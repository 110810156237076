(function () {
    'use strict';

    angular.module('app')
        .directive('navbar', NavbarDirective);

    NavbarDirective.$inject = ['CustomerService', 'PageTemplateService', 'CommonUtilService', '$rootScope', '$location','$window']

    function NavbarDirective(CustomerService, PageTemplateService, CommonUtilService, $rootScope, $location, $window) {
        var directive = {
            link: link,
            templateUrl: 'js/widget/navbar.html',
            restrict: 'E',
            scope: {}
        };
        return directive;

        function link($scope, element, attrs, $window) {

            $scope.commonUtilService = CommonUtilService;
            $scope.globalSearchInput = "";
            $scope.validSearchInput = "";
            $scope.globalSearchResults = {};
            $scope.showMobileSearchBar = false;
            $scope.showSearchResults = false;
            $scope.showSubLinks = ''

            $scope.onDesignerPage = function(){
                return $location.path().includes('designer')
            }

            $scope.hasSubLinks = function(category) {
               return category.subLinks.length > 0 || category.secondaryLinks.length > 0
            }

            $scope.bestSellers = {
                categoryTitle: 'Best Sellers',
                isStarred: true,
                categoryUrl: '/custom-products/best-sellers',
                categoryLinks: [
                    {
                        title: 'Short Sleeve Shirts',
                        icon: null,
                        url: 'custom-products/custom-clothing/t-shirts/short-sleeve',
                        imageUrl : "storyBanner/short-sleeve-shirts.jpeg",
                        altText : "One of our best sellers for custom products is tshirts"
                    },
                    {
                        title: 'Long Sleeve Shirts',
                        icon: null,
                        url: 'custom-products/custom-clothing/t-shirts/long-sleeve',
                        imageUrl : "storyBanner/long-sleeve-shirts.jpeg",
                        altText : "One of our best sellers for custom products is long sleeve shirts"
                    },
                    {
                        title: 'Hoodies and Sweatshirts',
                        url: '/custom-products/custom-clothing/hoodies-sweaters',
                        imageUrl : "storyBanner/hoodies.jpeg",
                        altText : "Hoodies are one of our best sellers"
                    },
                    {
                        title: 'Polo Shirts',
                        url: '/custom-products/custom-clothing/t-shirts/polos',
                        imageUrl : "storyBanner/polos.jpeg",
                        altText : "Custom polos are a favourite for our customers"
                    },
                    {
                        title: 'Tote Bags',
                        url: '/custom-products/custom-bags/tote-bags',
                        imageUrl : "storyBanner/tote-bags.jpeg",
                        altText : "We often print tote bags for our clients"
                    },
                    {
                        title: 'Hats',
                        url: '/custom-products/custom-clothing/hats',
                        imageUrl : "storyBanner/hats.jpeg",
                        altText : "Custom hats is one of our best sellers"
                    },
                    {
                        title: 'Jackets',
                        url: '/custom-products/custom-clothing/jackets',
                        imageUrl : "storyBanner/jackets.jpeg",
                        altText : "Custom jackets are a popular option in the winter."
                    },
                    {
                        title: 'Drinkware',
                        url: 'custom-products/promotional/drinkware',
                        imageUrl : "storyBanner/drinkware.jpeg",
                        altText : "Print custom bottles, mugs and more"
                    },
                    {
                        title: 'Notebooks',
                        url: '/custom-products/promotional/office-supplies/notebooks',
                        imageUrl : "storyBanner/notebooks.jpeg",
                        altText : "Custom print notebooks for your business"
                    },
                    {
                        title: 'Patches',
                        url: '/custom-products/patches',
                        imageUrl : "storyBanner/patches.jpeg",
                        altText : "Custom Patches are an amazing way to stand out. "
                    }
                ]
            }

            $scope.websiteLinks = [
                {
                    title: 'Products',
                    url: 'custom-products',
                    subLinks: [
                        {
                            categoryTitle: 'All Categories',
                            isStarred: false,
                            categoryUrl: 'custom-products',
                            categoryLinks: []
                        },
                        $scope.bestSellers,
                        {
                            categoryTitle: 'T-Shirts',
                            isStarred: false,
                            categoryUrl: '/custom-products/custom-clothing/t-shirts',
                            categoryLinks: [
                                {
                                    title: 'Short Sleeve',
                                    icon: null,
                                    url: 'custom-products/custom-clothing/t-shirts/short-sleeve'
                                },
                                {
                                    title: 'Long Sleeve',
                                    icon: null,
                                    url: 'custom-products/custom-clothing/t-shirts/long-sleeve'
                                },
                                {
                                    title: 'Performance Shirts',
                                    isStarred: false,
                                    icon: null,
                                    url: '/custom-products/custom-clothing/t-shirts/performance'
                                },
                                {
                                    title: 'Tank Tops and Sleeveless',
                                    isStarred: false,
                                    icon: null,
                                    url: '/custom-products/custom-clothing/t-shirts/tank-tops'
                                },
                                {
                                    title: 'Ladies Shirts',
                                    isStarred: false,
                                    icon: null,
                                    url: '/custom-products/custom-clothing/t-shirts/ladies'
                                },
                                {
                                    title: 'Kids Shirts',
                                    isStarred: false,
                                    icon: null,
                                    url: '/custom-products/custom-clothing/t-shirts/youth'
                                }
                            ]
                        },
                        {
                            categoryTitle: 'Apparel',
                            categoryUrl: 'custom-products/custom-clothing',
                            isStarred: false,
                            categoryLinks: [
                                {
                                    title: 'Hoodies and Sweatshirts',
                                    isStarred: false,
                                    icon: null,
                                    url: '/custom-products/custom-clothing/hoodies-sweaters'
                                },
                                {
                                    title: 'Sweatpants',    isStarred: false,
                                    icon: null,
                                    url: 'custom-products/custom-clothing/pants-bottoms/sweatpants'
                                },
                                {
                                    title: 'Polo Shirts',    isStarred: false,
                                    icon: null,
                                    url: 'custom-products/custom-clothing/t-shirts/polos'
                                },
                                {
                                    title: 'Dress Shirts',    isStarred: false,
                                    icon: null,
                                    url: 'custom-products/custom-clothing/dress-shirts'
                                },
                                {
                                    title: 'Athletic Wear',
                                    isStarred: false,
                                    icon: null,
                                    url: 'custom-products/custom-athletics'
                                },
                                {
                                    title: 'Sports Jerseys',
                                    isStarred: false,
                                    icon: null,
                                    url: 'custom-products/custom-athletics/jerseys'
                                },
                                {
                                    title: 'Sublimation',
                                    isStarred: false,
                                    icon: null,
                                    url: 'custom-products/custom-athletics/jerseys/sublimated-apparel'
                                },
                                {
                                    title: 'Jackets',
                                    isStarred: false,
                                    icon: null,
                                    url: 'custom-products/custom-clothing/jackets'
                                },
                                {
                                    title: 'Vests',
                                    isStarred: false,
                                    icon: null,
                                    url: 'custom-products/custom-clothing/jackets/vests'
                                },
                                {
                                    title: 'Shorts and Bottoms',
                                    isStarred: false,
                                    icon: null,
                                    url: 'custom-products/custom-clothing/pants-bottoms'
                                },
                                {
                                    title: 'Socks',
                                    isStarred: false,
                                    icon: null,
                                    url: '/custom-products/custom-clothing/socks'
                                },
                                {
                                    title: 'Ladies',
                                    isStarred: false,
                                    icon: null,
                                    url: 'custom-products/custom-clothing/ladies'
                                },
                                {
                                    title: 'Kids',
                                    isStarred: false,
                                    icon: null,
                                    url: 'custom-products/custom-clothing/youth'
                                }
                            ]
                        },
                        {
                            categoryTitle: 'Bags',
                            categoryUrl: 'custom-products/custom-bags',
                            isStarred: false,
                            categoryLinks: [
                                {
                                    title: 'Tote Bags',
                                    isStarred: false,
                                    icon: null,
                                    url: 'custom-products/custom-bags/tote-bags'
                                },
                                {
                                    title: 'Shopping Bags',
                                    isStarred: false,
                                    icon: null,
                                    url: 'custom-products/custom-bags/shopping'
                                },
                                {
                                    title: 'Duffel Bags',
                                    isStarred: false,
                                    icon: null,
                                    url: 'custom-products/custom-bags/duffel-bags'
                                },
                                {
                                    title: 'Backpacks',
                                    isStarred: false,
                                    icon: null,
                                    url: 'custom-products/custom-bags/custom-backpacks'
                                },
                                {
                                    title: 'Cinch Bags',
                                    isStarred: false,
                                    icon: null,
                                    url: 'custom-products/custom-bags/custom-cinch-bags'
                                },
                                {
                                    title: 'Laptop Bags',
                                    isStarred: false,
                                    icon: null,
                                    url: 'custom-products/custom-bags/custom-laptop-bags'
                                },
                                {
                                    title: 'Laptop and Tablet Sleeves',
                                    isStarred: false,
                                    icon: null,
                                    url: 'custom-products/custom-bags/custom-laptop-tablet-sleeves'
                                },
                            ]
                        },
                        {
                            categoryTitle: 'Hats',
                            categoryUrl: 'custom-products/custom-clothing/hats',
                            isStarred: false,
                            categoryLinks: [
                                {
                                    title: 'Dad Hats',
                                    isStarred: false,
                                    icon: null,
                                    url: 'custom-products/custom-clothing/hats/dad-hats'
                                },
                                {
                                    title: 'Flat Brimmed Hats',
                                    isStarred: false,
                                    icon: null,
                                    url: 'custom-products/custom-clothing/hats/flat-brimmed'
                                },
                                {
                                    title: 'Bucket Hats',
                                    isStarred: false,
                                    icon: null,
                                    url: 'custom-products/custom-clothing/hats/bucket-hats'
                                },
                                {
                                    title: 'Trucker Hats',
                                    isStarred: false,
                                    icon: null,
                                    url: 'custom-products/custom-clothing/hats/trucker-hats'
                                },
                                {
                                    title: 'Toques',
                                    isStarred: false,
                                    icon: null,
                                    url: 'custom-products/custom-clothing/hats/toques'
                                },
                                {
                                    title: 'Visors',
                                    isStarred: false,
                                    icon: null,
                                    url: 'custom-products/custom-clothing/hats/visors'
                                },
                                {
                                    title: 'Youth Hats',
                                    isStarred: false,
                                    icon: null,
                                    url: 'custom-products/custom-clothing/hats/youth'
                                },
                            ]
                        },
                        {
                            categoryTitle: 'Promotional Products',
                            categoryUrl: 'custom-products/promotional',
                            isStarred: false,
                            categoryLinks: [
                                {
                                    title: 'Drinkware',
                                    url: 'custom-products/promotional/drinkware',
                                    isStarred: false,
                                    icon: null,
                                },
                                {
                                    title: 'Notebooks',
                                    url: 'custom-products/promotional/office-supplies/notebooks',
                                    isStarred: false,
                                    icon: null,
                                },
                                {
                                    title: 'Pens',
                                    url: 'custom-products/promotional/office-supplies/custom-pens',
                                    isStarred: false,
                                    icon: null,
                                },
                                {
                                    title: 'Technology',
                                    url: 'custom-products/promotional/tech',
                                    isStarred: false,
                                    icon: null,
                                },
                                {
                                    title: 'Lanyards',
                                    url: 'custom-products/promotional/office-supplies/lanyards',
                                    isStarred: false,
                                    icon: null,
                                },
                                {
                                    title: 'Stickers',
                                    url: 'sticker-printing',
                                    isStarred: false,
                                    icon: null,
                                },
                                {
                                    title: 'Patches',
                                    url: 'custom-products/patches',
                                    isStarred: false,
                                    icon: null,
                                },
                                {
                                    title: 'Swag Boxes',
                                    url: 'custom-products/promotional/company-swag-box',
                                    isStarred: false,
                                    icon: null,
                                },
                            ]
                        },
                        {
                            categoryTitle: 'More Ways to Shop',
                            categoryUrl: '',
                            isStarred: false,
                            categoryLinks: [
                                {
                                    title: 'Made in Canada',
                                    icon: '/images/icons/navbar/Made_In_Canada.svg',
                                    isStarred: false,
                                    url: 'custom-products/custom-clothing/made-in-canada'
                                },
                                {
                                    title: 'Eco Friendly',
                                    icon: '/images/icons/navbar/Eco_Friendly.svg',
                                    isStarred: false,
                                    url: 'custom-products/eco-friendly-products'
                                },
                                {
                                    title: 'Brands',
                                    icon: '/images/icons/navbar/Brands.svg',
                                    isStarred: false,
                                    url: 'custom-products/brands'
                                },
                                {
                                    title: 'Bulk Products',
                                    icon: '/images/icons/navbar/bulk-products.svg',
                                    isStarred: false,
                                    url: '/custom-products/bulk-products'
                                }
                            ]
                        },
                    ],
                    secondaryLinks: [
                        {
                            categoryTitle: 'Shop by Use',
                            categoryUrl: 'custom-products/shop-by-use',
                            categoryLinks: [
                                {
                                    title: 'Corporate',
                                    icon: '/images/icons/navbar/Corporate.svg',
                                    url: 'custom-products/custom-clothing/corporate-apparel'
                                },
                                {
                                    title: 'Work and Safety',
                                    icon: '/images/icons/navbar/Work.svg',
                                    url: 'custom-products/custom-clothing/work-wear'
                                },
                                {
                                    title: 'School',
                                    icon: '/images/icons/navbar/School.svg',
                                    url: 'custom-products/custom-clothing/school'
                                },
                                {
                                    title: 'Streetwear',
                                    icon: '/images/icons/navbar/Streetwear.svg',
                                    url: 'custom-products/custom-clothing/street-wear'
                                },
                                {
                                    title: 'Sport',
                                    icon: '/images/icons/navbar/Sport.svg',
                                    url: 'custom-products/custom-athletics/sports'
                                },
                                {
                                    title: 'Uniform',
                                    icon: '/images/icons/navbar/Uniform.svg',
                                    url: 'custom-products/custom-clothing/work-wear/uniforms'
                                }
                            ]
                        },
                    ]
                },
                {
                    title: 'How to Order',
                    url: 'how-to-order',
                    subLinks: [],
                    secondaryLinks: []
                    // subLinks: []
                    // ,
                    // secondaryLinks: [
                    //     {
                    //         // categoryTitle: 'How to Order',
                    //         // categoryUrl: 'how-to-order',
                    //         categoryLinks: [
                    //             {
                    //                 title: 'Placing an Online Order',
                    //                 icon: '../../images/icons/navbar/Online_Order.svg',
                    //                 url: '/how-to-order?tab=ONLINE'
                    //             },
                    //             {
                    //                 title: 'Request a Quote',
                    //                 icon: '../../images/icons/navbar/Request_A_Quote.svg',
                    //                 url: '/how-to-order?tab=REQUEST'
                    //             },
                    //             {
                    //                 title: 'Contact Us',
                    //                 icon: '../../images/icons/navbar/Design_Help_Contact_Us.svg',
                    //                 url: '/contact'
                    //             },
                    //             {
                    //                 title: "Don't Have a Design?",
                    //                 icon: '../../images/icons/navbar/Dont_Have_A_Design.svg',
                    //                 url: $rootScope.getDesignHelpForm()
                    //             },
                    //         ]
                    //     },
                    // ]
                },
                // {
                //     title: 'Design',
                //     url: 'designer/atc-everyday-cotton-tee',
                //     secondaryLinks: [
                //         {
                //             categoryLinks: [
                //                 {
                //                     title: 'Design a Shirt',
                //                     icon: '../../images/icons/navbar/Design_A_Shirt.svg',
                //                     url: 'designer/gildan-ultra-cotton-t-shirt'
                //                 },
                //                 {
                //                     title: 'Design Help',
                //                     icon: '../../images/icons/navbar/Design_Help.svg',
                //                     url: 'design-help'
                //                 }
                //             ]
                //         }
                //     ],
                //     subLinks: []
                // },
                {
                    title: 'About',
                    url: 'about-us',
                    secondaryLinks: [
                        {
                            categoryLinks: [
                                // {
                                //     title: 'Contact Us',
                                //     icon: '../../images/icons/navbar/Contact_Us.svg',
                                //     url: 'contact'
                                // },
                                {
                                    title: 'About Us',
                                    icon: '../../images/icons/navbar/About_Us.svg',
                                    url: 'about-us'
                                },
                                {
                                    title: 'Our Facilities',
                                    icon: '../../images/icons/navbar/Our_Facilities.svg',
                                    url: 'our-facilities'
                                },
                                {
                                    title: 'Print Methods',
                                    icon: '../../images/icons/navbar/Print_Methods.svg',
                                    url: 'print-methods'
                                },
                                {
                                    title: 'Gallery',
                                    icon: '../../images/icons/navbar/Gallery.svg',
                                    url: 'gallery'
                                },
                                {
                                    title: 'Blog',
                                    icon: '../../images/icons/navbar/Blog.svg',
                                    url: 'blog'
                                },
                                // {
                                //     title: 'FAQ',
                                //     icon: '../../images/icons/navbar/FAQ.svg',
                                //     url: 'https://help.coastalreign.com'
                                // }
                            ]
                        }
                    ],
                    subLinks: []
                },
                {
                    title: 'FAQ',
                    url: 'https://help.coastalreign.com',
                    subLinks: [],
                    secondaryLinks: []
                }
            ]
            $scope.productCategoryLinks = []

            $scope.$watch('globalSearchInput', function() {
                if ($scope.globalSearchInput.length >= 3) {
                    // do the search
                    $scope.showSearchResults = true;
                    $scope.validSearchInput = $scope.globalSearchInput;
                    CustomerService.searchKeyword($scope.validSearchInput, true)
                        .then(function(results) {
                            // transform faq text content
                            if (results.faqArticlesResult.length > 0) {
                                _.map(results.faqArticlesResult, function(faqArticle) {
                                    var tempDiv = document.createElement('div');
                                    tempDiv.innerHTML = faqArticle.body;
                                    var textContent = tempDiv.textContent;
                                    // remove \n
                                    textContent = textContent.replace(/(\r\n|\n|\r)/gm," ");

                                    var allSentences = textContent.match( /[^\.!\?]+[\.!\?]+/g);

                                    var indexOfMatch = _.findIndex(allSentences, function(sentence) {
                                        return sentence.toLowerCase().includes($scope.validSearchInput.toLowerCase());
                                    })

                                    if (indexOfMatch > -1) {
                                        var firstIndex = indexOfMatch;
                                        var hasMoreTextBefore = '';
                                        if (firstIndex > 0) {
                                            hasMoreTextBefore = '. . . ';
                                        }
                                        firstIndex = firstIndex >= 0 ? firstIndex : 0;
                                        var before = allSentences.slice(firstIndex, indexOfMatch);

                                        var hasMoreTextAfter = '';
                                        var lastIndex = indexOfMatch;
                                        if (lastIndex < allSentences.length) {
                                            hasMoreTextAfter = ' . . .';
                                            var lastWord = allSentences[lastIndex];
                                            lastWord = lastWord.slice(0, lastWord.length - 1);
                                            allSentences[lastIndex] = lastWord;
                                        }
                                        var after = allSentences.slice(indexOfMatch, lastIndex + 1);
                                        textContent = hasMoreTextBefore + before.join(" ") + after.join(" ") + hasMoreTextAfter;
                                    } else {
                                        textContent = allSentences.slice(0, 1).join(" ").slice(0, -1) + ' . . .';
                                    }

                                    faqArticle.textBody = textContent;
                                })
                            }
                            _.forEach(results.pageResults, function(page) {
                                if (!page.previewImageUrl) {
                                    page.previewImageUrl = 'https://image.coastalreign.com/search_placeholder.jpg';
                                }
                            })
                            $scope.globalSearchResults = results;
                            // console.log($scope.globalSearchResults);
                        })
                } else {
                    // remove the search results
                    $scope.globalSearchResults = {};
                    $scope.validSearchInput = "";
                    $scope.showSearchResults = false;
                }
            })

            $scope.goToNavLink = function(url, event) {
                $scope.toggleSideNav(false);
            }

            $rootScope.$on('$routeChangeStart', function() {
                document.getElementById('searchInputElement').blur();
                $scope.showMobileSearchBar = false;
                $scope.showSubLinks = '';
                $scope.showSearchResults = false;
                $scope.globalSearchResults = {};
                $scope.globalSearchInput = '';
                $scope.validSearchInput = '';
            })

            $scope.mobileGlobalSearchDisplayClass = function() {
                return {
                    'hide-on-mobile': !$scope.showMobileSearchBar,
                    'show-on-mobile': $scope.showMobileSearchBar
                }
            }

            $scope.navSlideInClass = function() {
                return {
                    'slide-in': $rootScope.showSideNav,
                    'slide-out': $rootScope.showSideNav
                };
            }
            $scope.toggleSideNav = function(shouldShow) {
                $rootScope.showSideNav = shouldShow;
            }

            $scope.navigateToSearchMore = function() {
                if ($scope.validSearchInput) {
                    $location.url($rootScope.decodeURI("/search?query=" + $scope.validSearchInput));
                } else {
                    // $location.url('/search?query=');
                }
            }

            $scope.toggleSubLinks = function(websiteLink) {
                if (websiteLink && $scope.hasSubLinks(websiteLink) && !$scope.showSearchResults) {
                    $scope.showSubLinks = websiteLink.title;
                    $scope.subLinks = websiteLink;
                } else {
                    $scope.showSubLinks = '';
                    $scope.subLinks = {}
                }
            }

            $scope.toggleSearchResult = function(shouldShow) {
                if (_.isEmpty($scope.globalSearchResults)) {
                    $scope.showSearchResults = false;
                } else {
                    $scope.showSearchResults = shouldShow;
                }
            }

            $scope.toggleMobileSearchBar = function(shouldShow) {
                $scope.showMobileSearchBar = shouldShow;
                if (shouldShow) {
                    setTimeout(function(){
                        document.getElementById('searchInputElement').click();
                        document.getElementById('searchInputElement').focus();
                    })
                }
            }

            $scope.cleanPageName = function(name) {
                return PageTemplateService.cleanPageName(name);
            }

            $scope.getProductLink = function(product) {
                return CommonUtilService.buildProductUrl(product);
            }

            $scope.getProductDisplay = function(product){
                return CommonUtilService.getProductDisplay(product).url
            };

            $scope.compressImage = function(url) {
                return CommonUtilService.compressImage(url);
            }
        }
    }
})();