(function () {
    'use strict';

    angular.module('sharedModule')
        .directive('responsiveImage', ResponsiveImageDirective);

    ResponsiveImageDirective.$inject = ['$rootScope', 'CommonUtilService']

    function ResponsiveImageDirective($rootScope, CommonUtilService) {
        var directive = {
            restrict: "A",
            scope: {
                originalUrl: '@',
                isBanner: '@',
                isProductImage: '@',
                imageCanUpdate: '=',
                imagePath: '@?',
                imageSizes: '@?',
                noSrcset: '@'

            },
            link: linker
        }
        return directive;

        function linker ($scope, element, attrs) {

            // mobile, tablet, desktop breakpoints
            var DEFAULT_IMAGE_SIZES = "(max-width: 767px) 100vw, (max-width: 1023px) 100vw, 100vw"
            var NAMED_TRANSFORMATIONS = {
                'image-xs': "100w",
                'image-s': "250w",
                'image-sm': '350w',
                'image-m': "600w",
                'image-l': "1250w"
            }

            var NAMED_PRODUCT_TRANSFORMATIONS = {
                'product-img-xs': "100w",
                'product-img-s': "200w",
                'product-img-sm': '400w',
                'product-img-m': "600w",
                'product-img-l': "1250w"
            }

            function intialize(reload) {

                var imagekit = new ImageKit({
                    urlEndpoint: "https://image.coastalreign.com"
                });

                var src;
                var srcset = '';
                if ($scope.imagePath && !$scope.originalUrl) {
                    src = 'https://image.coastalreign.com/' + $scope.imagePath
                } else {
                    src = $scope.originalUrl;
                }

                if (!$scope.imageSizes) {
                    $scope.imageSizes = DEFAULT_IMAGE_SIZES;
                }

                var transformations = NAMED_TRANSFORMATIONS
                if ($scope.isProductImage) {
                    transformations = NAMED_PRODUCT_TRANSFORMATIONS
                }

                if (src) {
                    src = CommonUtilService.compressImage(src);
                    if (src.includes('image.coastalreign.com')) {
                        // create srcset images
                        if (!$scope.noSrcset) {
                            var transformedUrls = _.map(_.keys(transformations), function (transformationLabel) {
                                var transformedUrl = imagekit.url({
                                    src: src,
                                    transformation: [{
                                        "n": transformationLabel
                                    }]
                                })

                                return transformedUrl + " " + transformations[transformationLabel]
                            })

                            srcset = transformedUrls.join(", ");
                        }

                        if ($scope.isBanner) {
                            src = imagekit.url({
                                src: src,
                                transformation: [{
                                    "n": 'banner-quality'
                                }]
                            })
                        }

                    }
                }

                if ($rootScope.lazyLoadedImagesCache && $rootScope.lazyLoadedImagesCache.get(src)) {
                    element.addClass('imageLoadNoFade');
                    element.removeClass('lazyloaded');
                    element.removeClass('lazyload');
                    attrs.$set('src', src);
                    attrs.$set('srcset', srcset);
                    attrs.$set('sizes', $scope.imageSizes);
                } else {
                    if (attrs.$attr.lazyLoadedImage) {
                        if (reload) {
                            element.addClass('imageLoadNoFade');
                            element.removeClass('lazyloaded');
                            element.removeClass('lazyload');
                            attrs.$set('src', src);
                            attrs.$set('srcset', srcset)
                            attrs.$set('sizes', $scope.imageSizes)
                        } else {
                            attrs.$set('data-srcset', srcset)
                            attrs.$set('data-src', src);
                            attrs.$set('data-sizes', $scope.imageSizes)
                        }
                        if ($rootScope.lazyLoadedImagesCache) {
                            $rootScope.lazyLoadedImagesCache.put(src, true);
                        }
                    } else {
                        attrs.$set('src', src);
                        attrs.$set('srcset', srcset);
                        attrs.$set('sizes', $scope.imageSizes);
                    }
                }
            }

            intialize();
            if ($scope.imageCanUpdate) {
                $scope.$watch('originalUrl', function originalUrlUpdate(newValue, oldValue) {
                    if (oldValue && (newValue !== oldValue)) {
                        intialize(true);
                        // console.log(newValue);
                    }
                })
                $scope.$watch('imagePath', function imagePathUpdate(newValue, oldValue) {
                    if (oldValue && (newValue !== oldValue)) {
                        intialize(true);
                        // console.log('imagepath')
                    }
                })
            }
        }
    }
})();