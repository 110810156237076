(function () {
    'use strict';

    angular.module('sharedModule')
        .directive('skeletonProductList', SkeletonProductList);

    SkeletonProductList.$inject = ['matchmedia']

    function SkeletonProductList(matchmedia) {
        var directive = {
            restrict: 'EA',
            scope: {
                isPreview: '=?',
                showFilters: '='
            },
            templateUrl: 'shared/js/widget/skeleton-product-list.html',
            link: function ($scope, $elem, $attr) {
                $scope.onPhone = false;
                $scope.numElements = Array.from(Array(24));
                if ($scope.isPreview) {
                    $scope.numElements = [1, 2, 3];
                }

                $scope.watchers = [];

                $scope.watchers.push(matchmedia.onPhone(function(mediaQuery) {
                    if (mediaQuery.matches) {
                        $scope.onPhone = true;
                        if ($scope.isPreview) {
                            $scope.numElements = [1, 2];
                        }
                    }
                }));

                $scope.$on('$destroy', function() {
                    _.forEach($scope.watchers, function(watcher){
                        watcher();
                    })
                })

            }
        }
        return directive
    }
})();