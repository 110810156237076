


'use strict';

/* jshint ignore:start */
(function (app) {

    var that;
    var fromClient;
    var _
    if (app.name) {
        app = {};
        fromClient = true;

        that = window;
    } else {
        that = app;
    }

    var fromClient


    /**
     * Created by eddisonng on 2016-11-06.
     */

    app.orderTotalMinusTax = function(order, subtotalOverride) {

        return order.subTotalPrice - order.discountAmount + order.shippingCost
    }
//includes tax
    app.orderTotal = function(order){
        return order.subTotalPrice - order.discountAmount + order.shippingCost + order.gst + order.pst
    }

    app.recalculateOrderSubtotal = function(order) {
        //subtotal
        var newSubTotal = 0;
        for (var i = 0; i < order.listOfItems.length; i++) {
            newSubTotal = order.listOfItems[i].totalQty * order.listOfItems[i].price + newSubTotal
        }
        order.subTotalPrice = newSubTotal
    }

    app.recalculateDiscountAmount = function(order) {
        var setupDiscount = order.setupDiscountExempt ? 0 : order.fixedSetupDiscount;
        if (order.discountPercentage <= 1){
            order.discountAmount = order.fixedDiscountAmount +  setupDiscount + ((order.subTotalPrice - order.fixedDiscountAmount - setupDiscount) * order.discountPercentage)
        }
    }

    app.getAmountOwing = function(order){
        return app.orderTotal(order) - order.amountPaid;
    }

    app.createStatus = function(status, user, message){
        var username
        if (user && user.username){
            username = user.username
        }

        return {
            status :status,
            timeStamp : new Date(),
            username : username,
            message: message
        }
    }

    app.getLastStatusDate = function(order){
        if (order && order.status && order.status.length){
            return order.status[order.status.length - 1].timeStamp
        } else {
            return false
        }
    }

    app.getTrackOrderLink = function(order){
        if (order){
            var orderNumberParam = "?order=" + order.number;
            var postalCodeParam = "";
            if (order.shippingAddress) {
                postalCodeParam = order.shippingAddress.zip ? "&pc=" + order.shippingAddress.zip : "";
            }
            return encodeURI("/track-order" + orderNumberParam + postalCodeParam);
        }
    }



    app.getOrderStatus = function(order){
        if (order && order.status && order.status.length){
            return order.status[order.status.length - 1].status
        } else {
            return false
        }
    }

    app.setOrderStatus = function(order, status,user, message){
        if (order){
            var statusArray = [app.createStatus(status,user, message)]
            if (order.status && Array.isArray(order.status)){
                order.status = order.status.concat(statusArray)
            } else {
                order.status = statusArray
            }
        }
    }

    app.obfuscateNumber =  function (order){
        if (order && order.number) {
            var numberString = String(order.number);
            var obfuscatedChar = String.fromCharCode((new Date(order.datePlaced).getTime()) % 26 + 65); // 65 is the char code for 'A'
            return "CR" + obfuscatedChar + "-" + numberString;
        } else {
            return order.number;
        }
    }

    app.getAddressTooltipHelper = function(address) {
        if (address) {
            var phoneLine = address.phone ? '<div>' + address.phone + '</div>': '';
            var nameLine = address.name? '<div>' + address.name + '</div>': '';
            var street1 = '<div>' + address.street1 + '</div>';
            var street2 = '';
            if (address.street2) {
                street2 = '<div>' + address.street2 + '</div>';
            }
            var addressLine = '<div>' + address.city + ' ' + address.state + ' ' + address.zip + ' ' + address.country + '</div>'
            return phoneLine + nameLine + street1 + street2 + addressLine;
        }
    }


    if (fromClient) {
        for (var functionName in app) {
            window[functionName] = app[functionName]
        }
    }


})(typeof app === 'undefined' ? this : app);



