(function() {
    'use strict';

    angular.module('app')
        .factory('ProductService', ProductService)

    ProductService.$inject = ['$http', '$q', '$cacheFactory'];

    function ProductService($http, $q, $cacheFactory) {
        // sizes
        var productQueryCache = $cacheFactory.get('productsQuery');
        var fuzzySearchCache = $cacheFactory.get('fuzzyProductsQuery');
        if (!productQueryCache) {
            productQueryCache = $cacheFactory('productsQuery', {capacity: 1});
            fuzzySearchCache = $cacheFactory('fuzzyProductsQuery', {capacity: 1});
        }

        function getProductByUrl(url, category) {
            var route = '/api/products/name/' + url;
            if (category) {
                route += '/' + category
            }
            return $http.get(route)
                .then(function(response) {
                    return response.data;
                })
                .catch(function(err) {
                    return $q.reject(err);
                })
        }

        function fetchProducts(query, shouldCache) {
            // console.log(productQueryCache.info())
            var cache
            if (shouldCache) {
                cache = productQueryCache;
            } else {
                cache = false;
            }
            var getParams = {
                params: query,
                cache: false
            }
            return $http.get('/api/products/filter', getParams)
                .then(function(response) {
                    return response.data;
                })
                .catch(function(err) {
                    return $q.reject(err);
                })
        }

        function fuzzySearchProducts(queryString) {
            return $http.get('/api/products/search/fuzzy/' + queryString, {cache: false})
                .then(function(response) {
                    return response.data;
                })
                .catch(function(err) {
                    showError(err.data.message);
                    return $q.reject(err);
                })
        }

        return {
            getProductByUrl: getProductByUrl,
            fetchProducts: fetchProducts,
            fuzzySearchProducts: fuzzySearchProducts
        };
    }

})();
