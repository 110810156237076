(function () {
    'use strict';

    angular.module('sharedModule')
        .directive('bindHtmlCompile', BindHtmlCompileDirective);

    BindHtmlCompileDirective.$inject = ['$compile']

    // This directive recompiles the elements - allowing any links to reset if locale is defined
    function BindHtmlCompileDirective($compile) {

        var directive = {
            link: link,
            restrict: 'A',
        };
        return directive;

        function link(scope, element, attrs) {
            scope.$watch(function () {
                return scope.$eval(attrs.bindHtmlCompile);
            }, function (value) {
                // In case value is a TrustedValueHolderType, sometimes it
                // needs to be explicitly called into a string in order to
                // get the HTML string.
                element.html(value && value.toString());
                // If scope is provided use it, otherwise use parent scope
                var compileScope = scope;
                if (attrs.bindHtmlScope) {
                    compileScope = scope.$eval(attrs.bindHtmlScope);
                }
                $compile(element.contents())(compileScope);
            });
        }
    }
})();