(function () {
    'use strict';

    angular.module('sharedModule')
        .factory('SEOService', SEOService)

    SEOService.$inject = ['$rootScope', '$http', '$location', '$q', 'CommonUtilService', 'PageTemplateService', 'CountryService'];

    function SEOService($rootScope, $http, $location, $q, CommonUtilService, PageTemplateService, CountryService) {

        function generateMainPageSchema() {
            return {
                "@context": "https://schema.org",
                "@graph": [
                    {
                        "@type": "Organization",
                        "additionalType": "Corporation",
                        "@id": "https://coastalreign.com/",
                        "description": "Looking for custom clothing and t-shirt printing in Canada? Coastal Reign offers a variety of custom clothing services with free shipping and expert design help.",
                        "name": "Coastal Reign",
                        "email": "sales@coastalreign.com",
                        "department":
                            [
                                {
                                    "@type": "LocalBusiness",
                                    "address": {
                                        "@type": "PostalAddress",
                                        "addressLocality": "Calgary",
                                        "addressRegion": "AB",
                                        "postalCode": "T2P 0P7",
                                        "streetAddress": "903 8 Ave SW 9th Floor, Suite 900"
                                    },
                                    "geo": {
                                        "@type": "GeoCoordinates",
                                        "latitude": "51.0459601",
                                        "longitude": "-114.0817835"
                                    },
                                    "hasMap": "https://www.google.com/maps/place/Coastal+Reign+Printing/@51.0459601,-114.0817835,15z/data=!4m2!3m1!1s0x0:0xf73991494c057f87?sa=X&ved=1t:2428&ictx=111",
                                    "openingHours": ["Monday-Friday 9:00-17:00"],
                                    "name": "Coastal Reign Printing",
                                    "description": "Coastal Reign stands as your premier destination for custom clothing printing in Calgary. We specialize in delivering top-notch custom apparel solutions to businesses, schools, and sports teams throughout Canada. Our expertise lies in seamlessly blending high-quality embroidery and screen printing techniques with premium clothing brands. Whether you're in need of custom t-shirts, hoodies, hats, or promotional products, we are dedicated to bringing your printing ideas to vibrant life.",
                                    "image": "https://image.coastalreign.com/coastal-reign-canada-logo.png?tr=n-image-s",
                                    "telephone": "888-210-3971"
                                },
                                {
                                    "@type": "LocalBusiness",
                                    "address": {
                                        "@type": "PostalAddress",
                                        "addressLocality": "Toronto",
                                        "addressRegion": "ON",
                                        "postalCode": "M8Z5T6",
                                        "streetAddress": "Unit 3 - 310 Judson Street"
                                    },
                                    "geo": {
                                        "@type": "GeoCoordinates",
                                        "latitude": "43.6130283",
                                        "longitude": "-79.5144381"
                                    },
                                    "hasMap": "https://www.google.com/maps/place/Coastal+Reign+Printing/@43.6130283,-79.5144381,15z/data=!4m6!3m5!1s0x882b49d23cccd757:0xebbcec353dd53df7!8m2!3d43.6130283!4d-79.5144381!16s%2Fg%2F11rq17cn5k?entry=ttu",
                                    "openingHours": ["Monday-Friday 9:00-17:00"],
                                    "name": "Coastal Reign Printing",
                                    "description": "Coastal Reign stands as your premier destination for custom clothing printing in Toronto. We specialize in delivering top-notch custom apparel solutions to businesses, schools, and sports teams throughout Canada. Our expertise lies in seamlessly blending high-quality embroidery and screen printing techniques with premium clothing brands. Whether you're in need of custom t-shirts, hoodies, hats, or promotional products, we are dedicated to bringing your printing ideas to vibrant life.",
                                    "image": "https://image.coastalreign.com/coastal-reign-canada-logo.png?tr=n-image-s",
                                    "telephone": "647-494-6266"
                                },
                                {
                                    "@type": "LocalBusiness",
                                    "address": {
                                        "@type": "PostalAddress",
                                        "addressLocality": "Vancouver",
                                        "addressRegion": "BC",
                                        "postalCode": "V6P 3J9",
                                        "streetAddress": "8793 Cambie Street"
                                    },
                                    "geo": {
                                        "@type": "GeoCoordinates",
                                        "latitude": "43.6745765",
                                        "longitude": "-79.388752"
                                    },
                                    "hasMap": "https://www.google.com/maps/place/Coastal+Reign+Printing/@49.206168,-123.1201347,17z/data=!3m2!4b1!5s0x548674f0ea4fe15d:0x8cf3a5ded9a88fe4!4m6!3m5!1s0x5486769f38599947:0xfab5117abf828aea!8m2!3d49.2061645!4d-123.1175598!16s%2Fg%2F11b7cbswwg?entry=ttu",
                                    "openingHours": [
                                        "Monday-Friday 9:00-17:00"
                                    ],
                                    "name": "Coastal Reign Printing",
                                    "description": "Coastal Reign stands as your premier destination for custom clothing printing in Vancouver. We specialize in delivering top-notch custom apparel solutions to businesses, schools, and sports teams throughout Canada. Our expertise lies in seamlessly blending high-quality embroidery and screen printing techniques with premium clothing brands. Whether you're in need of custom t-shirts, hoodies, hats, or promotional products, we are dedicated to bringing your printing ideas to vibrant life.",
                                    "image": "https://image.coastalreign.com/coastal-reign-canada-logo.png?tr=n-image-s",
                                    "telephone": "(416) 912-5544"
                                }
                            ],
                        "sameAs": [
                            "https://www.facebook.com/coastalreignprint",
                            "https://twitter.com/coastalreign",
                            "https://www.linkedin.com/company/coastal-reign-printing-ltd",
                            "https://www.instagram.com/coastalreign/"
                        ],
                        "url": "https://coastalreign.com/",
                        "image": "https://image.coastalreign.com/coastal-reign-canada-logo.png",
                        "telephone": "1-888-210-3971",
                        "contactPoint": {
                            "@type": "ContactPoint",
                            "telephone": [
                                "+1-888-210-3971"
                            ],
                            "availableLanguage": [
                                "English"
                            ],
                            "contactType": "customer service",
                            "areaServed": {
                                "@type": "AdministrativeArea",
                                "address": {
                                    "@type": "PostalAddress",
                                    "addressCountry": [
                                        "CA", "US"
                                    ]
                                }
                            }
                        }
                    }
                ]
            }
        }

        function generatePageTemplateSchema(pageTemplate, breadcrumbArray) {
            // figure out what kind of page it is
            var pageType = 'WebPage';
            var mainEntity = {};

            var faqSection = _.find(pageTemplate.sections, function (section) {
                return section.sectionProps.sectionType === modelService.PAGE_SECTIONS.FAQ;
            })

            if (faqSection) {
                pageType = 'FAQPage';
                var questions = faqSection.sectionProps.questions.dataProps.dataTypeList;
                var answers = faqSection.sectionProps.answers.dataProps.dataTypeList;
                var faqList = _.map(questions, function (question, index) {
                    return {
                        question: question,
                        answer: answers[index]
                    }
                });

                mainEntity = _.map(faqList, function (faqQA) {
                    return {
                        "@type": "Question",
                        "name": faqQA.question.dataProps.textValue,
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": faqQA.answer.dataProps.richTextValue
                        }
                    }
                })
            }

            var previewImage = $rootScope.compressImage(pageTemplate.previewImageUrl);
            var mainEntityVal = mainEntity ? mainEntity : null;

            var returnObj = {
                "@context": "https://schema.org",
                "@type": pageType,
                "breadcrumb": generateBreadcrumb(breadcrumbArray),
            }

            if (mainEntityVal) {
                returnObj.mainEntity = mainEntityVal;
            }
            if (previewImage) {
                returnObj.primaryImageOfPage =  {
                    "@type": "ImageObject",
                    "name": pageTemplate.previewImageAltText,
                    "url": $rootScope.compressImage(pageTemplate.previewImageUrl),
                    "embedUrl": $rootScope.compressImage(pageTemplate.previewImageUrl)
                }
            }

            return returnObj;
        }

        function generateBreadcrumb(breadcrumbArray) {
            var breadcrumbStructuredListElement = _.map(breadcrumbArray, function (breadcrumbObject, index) {
                var pageUrl = (breadcrumbObject.pageUrl === '/')? '' : '/' + breadcrumbObject.pageUrl;
                return {
                    "@type": "ListItem",
                    "position": index + 1,
                    "name": PageTemplateService.cleanPageName(breadcrumbObject.targetKeyword),
                    "item": {
                        "name": PageTemplateService.cleanPageName(breadcrumbObject.targetKeyword),
                        "id": 'https://coastalreign.com' + pageUrl
                    }
                }
            });

            var breadcrumbData = {
                "@context": "https://schema.org/",
                "@type": "BreadcrumbList",
                "itemListElement": breadcrumbStructuredListElement
            }

            return breadcrumbData;
        }

        function generateProductPLPSchema(productFromPLP) {
            var priceCurrency = 'CAD';
            var productPrice = productFromPLP.averagePrice;

            if ($rootScope.fromUS()) {
                priceCurrency = 'USD';
                productPrice = calculationService.ca2us(productPrice, priceCurrency);
            }
            // todo: update description to be product specific
            return [{
                "@context": "https://schema.org/",
                "@type": "Product",
                "name": productFromPLP.name,
                "sku": productFromPLP.sku,
                "image": CommonUtilService.compressImage(productFromPLP.colours[0].front, 'image-sm'),
                "description": "Have your custom design printed on a " + productFromPLP.name + ". We offer free shipping and expert design help.",
                "brand": {
                    "@type": "Brand",
                    "name": productFromPLP.brand
                },
                "offers": {
                    "@type": "Offer",
                    "url": $location.protocol() + '://coastalreign.com' + productFromPLP.productUrl,
                    "priceCurrency": priceCurrency,
                    "price": productPrice.toFixed(2),
                }
            }];
        }

        function generateProductSchema(product, breadcrumbArray) {
            var category = _.find(categoryService.allCategories, function (cat) {
                return product.category == cat.name
            })
            var breadcrumbSchemaObject = generateBreadcrumb(breadcrumbArray);
            var priceCurrency = 'CAD'
            var offerCount = 1000;
            var lowCount = 25;
            try {
                 lowCount = calculationService.getStartingQuantityForProduct(product);
            } catch(e) {

            }
            var lowCountPrice = calculationService.calculateAveragePrice(product, lowCount, category).price
            var highCountPrice = calculationService.calculateAveragePrice(product, offerCount, category).price

            var localeUrl = ''
            if ($rootScope.fromUS()) {
                priceCurrency = 'USD';
                lowCountPrice = calculationService.ca2us(lowCountPrice, priceCurrency);
                highCountPrice = calculationService.ca2us(highCountPrice, priceCurrency);
                 localeUrl = '/en-us'
            }

            var brandName;
            if (product.brand === 'No Brand' || !product.brand) {
                brandName = 'Coastal Reign'
            } else {
                brandName = product.brand
            }
            var productGroup = {
                '@context': "https://schema.org/",
                "@type": "ProductGroup",
                'productGroupID': product.sku,
                "name": product.name,
                "sku": product.sku,
                "brand": {
                    "@type": "Brand",
                    "name": brandName
                },
                "offers": {
                    "@type": "AggregateOffer",
                    'url': $location.protocol() + '://coastalreign.com' + localeUrl + CommonUtilService.buildProductUrl(product),
                    "priceCurrency": priceCurrency,
                    "highPrice": lowCountPrice.toFixed(2),
                    "lowPrice": highCountPrice.toFixed(2),
                    "offerCount": offerCount,
                    "price": lowCountPrice.toFixed(2),
                    'availability': "https://schema.org/InStock"
                },
                "description": "Have your custom design printed on a " + product.name + ". We offer free shipping and expert design help.",
                'url': $location.protocol() + '://coastalreign.com' + localeUrl + CommonUtilService.buildProductUrl(product),
                'variesBy': [
                    "https://schema.org/size",
                    "https://schema.org/color"
                ]
            }


            function createProductVariant(product, colourSchema, size) {
                var variantName = colourSchema.name + ' ' + product.name + ' in ' + size;
                var variantImages = _.map(_.reject(
                    [colourSchema.front, colourSchema.back], function (imageUrl) {
                        return _.isEmpty(imageUrl)
                    }), function (image) {
                    return CommonUtilService.compressImage(image, 'image-sm')
                });
                var variantUrl = productGroup.url + '?selectedColour=' + encodeURI(colourSchema.name) + '&size=' + encodeURI(size);
                return {
                    '@type': "Product",
                    "sku": product.sku + '_' + CommonUtilService.replaceSpaceWithUnderscore(colourSchema.name) + '_' + CommonUtilService.replaceSpaceWithUnderscore(size),
                    // "inProductGroupWithID": product.sku,
                    "image": variantImages,
                    "name": variantName,
                    "description": "Have your custom design printed on a " + variantName,
                    "color": colourSchema.name,
                    "size": size,
                    "offers": {
                        "@type": "AggregateOffer",
                        "url": variantUrl,
                        "priceCurrency": priceCurrency,
                        "highPrice": lowCountPrice.toFixed(2),
                        "lowPrice": highCountPrice.toFixed(2),
                        "offerCount": offerCount,
                        "price": lowCountPrice.toFixed(2),
                        'availability': "https://schema.org/InStock"
                    }
                }
            }

            var variantsSchema = [];
            _.forEach(product.colours, function(colourSchema) {
                if (product.sizes.length === 1) {
                    variantsSchema.push(createProductVariant(product, colourSchema, 'One Size'))
                } else {
                    _.forEach(product.sizes, function (size) {
                        variantsSchema.push(createProductVariant(product, colourSchema, size))
                    })
                }
            })
            productGroup.hasVariant = variantsSchema;
            return [
                breadcrumbSchemaObject,
                productGroup
            ]
        }

        function updateCanonicalTag(routeURL) {
            var head = document.getElementsByTagName('head')[0];
            var element = document.querySelector(`link[rel='canonical']`) || null
            if (element) {
                head.removeChild(element);
            }

            element = document.createElement('link');
            head.appendChild(element);
            element.setAttribute('rel', 'canonical')
            element.setAttribute('href', routeURL)
        }

        // baseUrl = canadian url
        function updateHrefLangTags(host, basePath) {
            var head = document.getElementsByTagName('head')[0];
            // 1) clear all hreflang elements first
            var elements = document.querySelectorAll(`link[hreflang]`)
            if (elements.length > 0) {
                for(var i = 0; i < elements.length; i ++) {
                    var hreflangElement = elements[i]
                    head.removeChild(hreflangElement);
                }
            }

            //2. create new tags given base url
            if (!_.isUndefined(basePath)) {
                var caUrl = host + basePath;
                if (basePath == '/') {
                    basePath = ''
                }
                var usUrl = host + '/en-us' + basePath;

                //1. x-default
                var defaultElement = document.createElement('link');
                head.appendChild(defaultElement);
                defaultElement.setAttribute('rel', 'alternate')
                defaultElement.setAttribute('hreflang', 'x-default')
                defaultElement.setAttribute('href', encodeURI(usUrl))

                //2. en-ca
                var enCAElement = document.createElement('link');
                head.appendChild(enCAElement);
                enCAElement.setAttribute('rel', 'alternate')
                enCAElement.setAttribute('hreflang', 'en-CA')
                enCAElement.setAttribute('href', encodeURI(caUrl))

                //3. en-us
                var enUSElement = document.createElement('link');
                head.appendChild(enUSElement);
                enUSElement.setAttribute('rel', 'alternate')
                enUSElement.setAttribute('hreflang', 'en-US')
                enUSElement.setAttribute('href', encodeURI(usUrl))
            }
        }

        function createMetaTag(key, identifier, content){
            var metas = document.getElementsByTagName("meta");
            var meta = _.find(metas, function(meta){
                return meta.getAttribute(key) == identifier
            });
            if (meta){
                meta.content = content
            } else {
                $('head').append('<meta '+ key + " = \"" + identifier + "\"" + ' content=\"' + content + '\" />');
            }
        }

        return {
            generateMainPageSchema: generateMainPageSchema,
            generatePageTemplateSchema: generatePageTemplateSchema,
            generateProductPLPSchema: generateProductPLPSchema,
            generateProductSchema: generateProductSchema,
            updateCanonicalTag: updateCanonicalTag,
            updateHrefLangTags: updateHrefLangTags,
            createMetaTag: createMetaTag
        };
    }

})();
