(function () {
    'use strict';

    angular.module('sharedModule')
        .directive('jsonld', ['$filter', '$sce', function ($filter, $sce) {
            return {
                restrict: 'E',
                template: function () {
                    return '<script type="application/ld+json" ng-bind-html="boundData"></script>';
                },
                scope: {
                    json: '=json'
                },
                link: function ($scope, element, attrs) {
                    $scope.boundData;

                    function activate() {
                        $scope.$watch('json', function() {
                            if ($scope.json) {
                                $scope.boundData = $sce.trustAsHtml($filter('json')($scope.json));
                            }
                        })
                    }

                    activate();
                }
            };
        }])
})();