(function() {
    'use strict';

    angular.module('app')
        .factory('BrandService', BrandService)

    BrandService.$inject = ['$http', '$q'];

    function BrandService($http, $q) {

        var brandsPromise = null

        function getAllBrands() {
            if (!brandsPromise) {
                brandsPromise = $http.get('/api/brands')
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (err) {
                        showError(err.data.message);
                        return $q.reject(err);
                    })
            }
            return brandsPromise;
        }

        return {
            getAllBrands: getAllBrands
        };
    }

})();
