(function () {
    'use strict';

    angular.module('sharedModule')
        .directive('lazyLoadedImage', LazyLoadedImageDirective);

    LazyLoadedImageDirective.$inject = ['$rootScope']

    function LazyLoadedImageDirective($rootScope) {

        var linker = function ($scope, element, attrs) {
            element.addClass('lazyload');
        };

        return {
            restrict: "A",
            link: linker
        };
    }
})();