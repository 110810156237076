(function () {
    'use strict';

    angular.module('app')
        .directive('footer', FooterDirective);

    FooterDirective.$inject = ['$rootScope', '$location']

    function FooterDirective($rootScope, $location) {
        var directive = {
            link: link,
            templateUrl: 'js/widget/footer.html',
            restrict: 'EA',
            scope: {}
        };
        return directive;

        function link($scope, element, attrs) {

        }
    }
})();