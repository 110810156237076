(function() {
    'use strict';

    angular.module('sharedModule')
        .factory('CommonUtilService', CommonUtilService)

    CommonUtilService.$inject = [];

    // shared by both admin and public, if this starts getting bloated create more specific services
    function CommonUtilService() {

        function getDateString(timestamp, format) {
            var formatToUse = format ? format: "LL"
            return moment(timestamp).local().format(formatToUse);
        }

        function getAddressTooltip(address) {
            return getAddressTooltipHelper(address);
        }

        function getAvailablePrints(product) {
            if (product.type === 'Promotional Product') {
                return _.uniq(_.map(product.promoPrintMethods, function(specificPrintMethod) {
                    var found = _.find(_.values(calculationService.promoProductDecorationGrouping), function(promoPrintGroup) {
                        return promoPrintGroup.list.includes(specificPrintMethod);
                    })
                    if (found) {
                        return found.name
                    }
                }));
            } else if (product.type === 'Sticker') {
                return ['Sticker']
            } else if (product.type === 'Patch') {
                return ['Patch']
            } else if (product.category === 'Sublimation') {
                return ['Sublimation']
            } else {
                // garment
                if (calculationService.onlyEmbroideryAllowed(product.foundCategory)) {
                    return ["Embroidery"]
                } else {
                    return ["Print", "Embroidery"]
                }
            }
        }
        function isProductDesignable(product) {
            return product.productStatus === 'LIVE'
                && product.type != 'Sticker'
                && product.category != 'Sublimation'
                && product.type != 'Patch'
        }

        var imagekit = new ImageKit({
            urlEndpoint: "https://image.coastalreign.com"
        });

        var FILE_UPLOAD_URLS = [
            'file-uploads-cr.s3.amazonaws.com',
            's3.amazonaws.com/file-uploads-cr',
            's3-us-west-2.amazonaws.com/product-image',
        ]

        var IMAGE_FILE_FORMATS = [
            'jpeg', 'jpg', 'png', 'webp', 'svg', 'avif'
        ]


        function compressImage(url, namedTransformation){
            var compressedUrl = url;
            if (url) {
                if (url.indexOf("cloudinary") >= 0){
                    // var indexOfUpload = url.indexOf("upload") + 7
                    // if (indexOfUpload >= 7){
                    //     var left = url.substring(0, indexOfUpload)
                    //     var right = url.substring(indexOfUpload, url.length)
                    //     var finalUrl = left + "f_auto,q_auto,w_1400/" + right
                    //     // console.log(finalUrl)
                    //     return finalUrl
                    // } else {
                    //     return url
                    // }
                } else {
                    var foundUrlHeader = _.find(FILE_UPLOAD_URLS, function(matchingUrl) {
                        return url.indexOf(matchingUrl) >= 0
                    });
                    if (foundUrlHeader) {
                        var dotPos = url.lastIndexOf(".");
                        var fileFormat = url.substring(dotPos + 1);
                        if (IMAGE_FILE_FORMATS.includes(fileFormat.toLowerCase())){
                            // get compressed img
                            var transformation = [];
                            if (namedTransformation) {
                                transformation = [{
                                    "n": namedTransformation
                                }]
                            }
                            compressedUrl = imagekit.url({
                                src: url.replace(foundUrlHeader, "image.coastalreign.com"),
                                transformation: transformation
                            })
                        }
                    }
                }
            }
            return compressedUrl;
        }

        var copyToClipboard = function(str){
            function listener(e) {
                e.clipboardData.setData("text/html", str);
                e.clipboardData.setData("text/plain", str);
                e.preventDefault();
            }

            var el = document.createElement('textarea');
            el.value = str;
            document.body.appendChild(el);
            el.select();
            document.addEventListener("copy", listener);
            document.execCommand('copy');
            document.removeEventListener("copy", listener);
            document.body.removeChild(el);
        }

        // URL methods
        function buildUrl(location){
            return replaceSpaceWithHyphensAndLower(location.city) + "-" + replaceSpaceWithHyphensAndLower(location.province)
        }

        function buildLocationUrl(location){
            return modelService.CUSTOM_URL_LINK_FRAGMENTS.T_SHIRT_PRINTING + buildUrl(location)
        }

        function buildLocationSPUrl(location){
            return modelService.CUSTOM_URL_LINK_FRAGMENTS.SCREEN_PRINTING + buildUrl(location)
        }

        function buildLocationEMBUrl(location){
            return modelService.CUSTOM_URL_LINK_FRAGMENTS.EMBROIDERY + buildUrl(location)
        }

        function buildCategoryUrl(category){
            return category.path;
        }


        function getGroupUrl(groupName){
            return "/products/" + replaceSpaceWithHyphensAndLower(groupName)
        }

        function buildProductUrl(product){
            // need to build special product urls for stickers and patches
            if (product.productUrlOverride) {
                return product.productUrlOverride;
            } else {
                return "/products/" + replaceSpaceWithHyphensAndLower(product.category) + "/" + replaceSpaceWithHyphensAndLower(product.name)
            }
        }

        function buildProductDesignUrl(product){
            return "/designer/" + replaceSpaceWithHyphensAndLower(product.name)
        }

        function getProductDisplay(product) {
            var url;
            var colour;
            if (!product.useDisplayPhoto) {
                if (product.displayColour) {
                    colour = product.displayColour.name;
                    url = product.displayColour.front
                } else if (product.supplementaryImages && product.supplementaryImages[0] && product.supplementaryImages[0].isAnchored) {
                    url = product.supplementaryImages[0].imageUrl
                } else {
                    colour = product.colours[0].name;
                    url = product.colours[0].front;
                }
            } else {
                url = "../images/displayImages/Custom_Printed_" + replaceSpaceWithUnderscore(product.name) + ".png"
            }

            var altText = 'An image of a Custom ' + product.name;
            if (colour) {
                altText = 'An image of a Custom ' + product.name + ' in ' + colour
            }

            return {
                colour: colour,
                url: url,
                altText : altText
            }
        }

        function replaceSpaceWithUnderscore(word){
            return word.replace(/\s/g, "_")

        }

        function replaceSpaceWithHyphensAndLower(word){
            word = word.replace(/\s/g, "-")
            return word.toLowerCase()
        }

        // Current blob size limit is around 500MB for browsers
        // used to bypass CORS browser download
        function downloadResource(url, filename) {
            function forceDownload(blob, filename) {
                var a = document.createElement('a');
                a.download = filename;
                a.href = blob;
                // For Firefox https://stackoverflow.com/a/32226068
                document.body.appendChild(a);
                a.click();
                a.remove();
            }

            if (!filename) filename =
                decodeURIComponent(url.split('\\').pop().split('/').pop());
            fetch(url, {
                headers: new Headers({
                    'Origin': location.origin
                }),
                mode: 'cors'
            })
                .then(response => response.blob())
                .then(blob => {
                    let blobUrl = window.URL.createObjectURL(blob);
                    forceDownload(blobUrl, filename);
                })
                .catch(e => console.error(e));
        }

        function toTitleCase(str) {
            if(!str){
                return
            }
            var str = str.toLowerCase();
            var strnew = "";
            var i = 0;
            for (i = 0; i < str.length; i++) {
                if (i == 0) {
                    strnew = strnew + str[i].toUpperCase();
                } else if (i != 0 && str[i - 1] == " ") {
                    strnew = strnew + str[i].toUpperCase();
                } else {
                    strnew = strnew + str[i];
                }
            }
            return strnew
        }


        return {
            getDateString: getDateString,
            getAddressTooltip: getAddressTooltip,
            compressImage: compressImage,
            isProductDesignable: isProductDesignable,
            replaceSpaceWithUnderscore: replaceSpaceWithUnderscore,
            replaceSpaceWithHyphensAndLower: replaceSpaceWithHyphensAndLower,
            buildUrl: buildUrl,
            buildLocationUrl: buildLocationUrl,
            buildLocationSPUrl: buildLocationSPUrl,
            buildLocationEMBUrl: buildLocationEMBUrl,
            buildCategoryUrl: buildCategoryUrl,
            getGroupUrl: getGroupUrl,
            getAvailablePrints: getAvailablePrints,
            buildProductUrl: buildProductUrl,
            buildProductDesignUrl: buildProductDesignUrl,
            getProductDisplay: getProductDisplay,
            downloadResource: downloadResource,
            copyToClipboard: copyToClipboard,
            toTitleCase: toTitleCase,
        }

    }
})();