(function() {
    'use strict';

    angular.module('app')
        .factory('PageTemplateService', PageTemplateService)

    PageTemplateService.$inject = ['$http', '$q', 'CountryService', '$cacheFactory'];

    function PageTemplateService($http, $q, CountryService, $cacheFactory) {

        var pageTemplateCache = $cacheFactory.get('pageTemplate');
        var breadcrumbCache = $cacheFactory.get('breadcrumbCache');
        var productCategoryUmbrellaCache = $cacheFactory.get('productCategoryUmbrellaCache');
        if (!pageTemplateCache) {
            pageTemplateCache = $cacheFactory('pageTemplate', {capacity: 3});
            breadcrumbCache = $cacheFactory('breadcrumb', {capacity: 3});
            productCategoryUmbrellaCache = $cacheFactory('productCategoryUmbrellaCache', {capacity: 3});
        }

        function findPageTemplateByUrl(url, locale, viewToken) {
            return $http.get('/api/pageTemplates/find',
                {params: {url: url, locale: locale, viewToken: viewToken}, cache: false})
                .then(function(response) {
                    return response.data;
                })
                .catch(function(err) {
                    return $q.reject(err);
                })
        }

        function getAllLivePageTemplates(locale) {
            return $http.get('/api/pageTemplates/', {params: {locale: locale}})
                .then(function(response) {
                    return response.data;
                })
                .catch(function(err) {
                    return $q.reject(err);
                })
        }


        function getBreadcrumb(url) {
            return $http.get('/api/pageTemplates/breadcrumb',
                {params: {url: url, locale: CountryService.getCurrentLocale()}, cache: false})
                .then(function(response) {
                    return response.data;
                })
                .catch(function(err) {
                    return $q.reject(err);
                })
        }


        // todo duplicate in admin
        function cleanPageName(targetKeyword, replaceLocation) {
            //1. remove instances of word custom
            targetKeyword = targetKeyword.replace(' Custom ', ' ');
            targetKeyword = targetKeyword.replace('Custom ', '');

            //2. replace location
            if (replaceLocation) {
                targetKeyword = targetKeyword.replace(/in Canada$/i, "");
                targetKeyword = targetKeyword.replace(/Canada$/i, "");
                targetKeyword = targetKeyword.replace(/in Vancouver$/i, "");
                targetKeyword = targetKeyword.replace(/Vancouver$/i, "");
            }

            return targetKeyword;
        }

        function getProductCategoryUmbrella(pageId) {
            return $http.get('/api/pageTemplates/productCategoryUmbrella',
                {params: {parentPage: pageId, locale: CountryService.getCurrentLocale()}})
                .then(function(response) {
                    return response.data;
                })
                .catch(function(err) {
                    return [];
                })
        }


        return {
            findPageTemplateByUrl: findPageTemplateByUrl,
            getBreadcrumb: getBreadcrumb,
            getAllLivePageTemplates: getAllLivePageTemplates,
            cleanPageName: cleanPageName,
            getProductCategoryUmbrella: getProductCategoryUmbrella
        };
    }

})();
