/**
 * Created by richa_000 on 2016-05-02.
 */

(function() {
    'use strict';
    angular.module('AuthService', [])
        .factory('AuthToken', function ($window) {
            var authTokenFactory = {};
            // get a token out of local storage
            authTokenFactory.getToken = function () {
                return $window.localStorage.getItem('userToken');
            };

            // set token to this (login token), otherwise set it to nothing (log out purposes)
            authTokenFactory.setToken = function (response) {
                if (response) {
                    $window.localStorage.setItem('userToken', response.token);
                } else
                    $window.localStorage.removeItem('userToken');
            };


            authTokenFactory.logOut = function () {
                authTokenFactory.setToken();
            };

            authTokenFactory.isLoggedIn = function () {
                if (authTokenFactory.getToken()) {
                    return true;
                } else {
                    return false;
                }

            };

            authTokenFactory.getCurrentUserDetails = function () {
                var token = authTokenFactory.getToken();
                if (token) {
                    try {
                        var payload = token.split('.')[1];
                        payload = $window.atob(payload);
                        payload = JSON.parse(payload);
                        return payload;
                    } catch (e) {
                        authTokenFactory.setToken();
                    }
                } else return {};
            };

            return authTokenFactory;
        })

        .factory('AuthInterceptor', function ($q, $location, AuthToken) {
            var interceptorFactory = {};
            interceptorFactory.request = function (config) {
                var token = AuthToken.getToken();
                // if there is a token
                // if the request is being made to cloudinary do not attach the token to the header.
                if (token && config.url.indexOf('api.cloudinary.com') === -1) {
                    config.headers['cr-access-token'] = token; //add to header
                }
                return config;
            };

            interceptorFactory.responseError = function (response) {
                if (response.status === 401) {
                    AuthToken.setToken();
                    // $location.path('/login');
                }

                return $q.reject(response);

            };
            return interceptorFactory;
        });
})();