(function(exports) {
    var _
    var mS
    var merge
    if (typeof window !== 'undefined') {
        mS = modelService
        _ = window._
        merge = deepmerge;
    } else {
        _ = require("../../../node_modules/underscore")
        mS = require("../services/models")
        merge = require('deepmerge');
    }

var sizeCodeToName = {
    "XS": "X-Small",
    "S": "Small",
    "M": "Medium",
    "L": "Large",
    "XL": "X-Large",
    "2XL": "XX-Large",
    "3XL": "XXX-Large",
    "4XL": "XXXX-Large",
    "5XL": "XXXXX-Large",
}

    // against CAD,
    var fxMap = {
        'CAD': 1,
        'USD': 0.85, // update this if need to change
        'LEGACY_USD': 0.85 // DO NOT UPDATE - legacy orders used 0.85 as rate
    }

var shippingPercentageWithinRange = 10;
var shippingChargesOver300 = 0.50
var csrFeeBlank = 15.45 //annual increase prone
var csrFeePrint = 40 //annual increase prone
var customizationCostPerUnit = 7.50; //annual increase prone
var splitShipmentHandlingCost = 8.50; //annual increase prone
var USDToCADExchange = 1.39; //https://www.google.com/search?q=usd+to+cad&oq=usd+to+cad&gs_lcrp=EgZjaHJvbWUqDggAEEUYJxg7GIAEGIoFMg4IABBFGCcYOxiABBiKBTISCAEQABhDGIMBGLEDGIAEGIoFMhIIAhAAGEMYgwEYsQMYgAQYigUyDwgDEAAYQxixAxiABBiKBTISCAQQABhDGIMBGLEDGIAEGIoFMgwIBRAAGEMYgAQYigUyDwgGEAAYQxixAxiABBiKBTIPCAcQABhDGLEDGIAEGIoFMg8ICBAAGEMYsQMYgAQYigUyDwgJEAAYQxixAxiABBiKBdIBBzc1MGowajeoAgCwAgA&sourceid=chrome&ie=UTF-8
var borderDutiesFixed = 41.20; //annual increase prone
var dutiesAndTaxMarkup = 1.2; //https://docs.google.com/spreadsheets/d/1ZvxJtKsOhCoeNcJNtARK0Z6hiAwGUg6pZY7ygOiq-Jg/edit#gid=0
var pressCost = 4.635 //annual increase prone
var perimeterSewingCost = 5.15 //annual increase prone
var individualPackagingCost = 2;
var sewnOnTagsCost = 2;
var USD_INVOICING_CONVERSION = 0.25;
var US_SHIPMENT_SIZE_VALUE = 800;
var NON_GUARANTEED_EXTRA_DAYS = 3;

////////////////////
// Promo Products (PCNA) //
////////////////////

var PCNAShippingMarkup = 1.05;
var PCNAPromoProductRushProductionFee = 55; // flat fee of 50 treat as markup
var PCNAPromoProductMinimumOrderFlatFee = 75; // sept 16, 2023

// todo: should be own model in db
var promoProductDecorationGrouping = {
    print: {
        name: 'Print',
        list: [
            mS.PROMO_PRINT_NAME.SCREEN_PRINT,
            mS.PROMO_PRINT_NAME.PAD_PRINT
        ]},
    laser: {
        name: 'Laser',
        list: [
            mS.PROMO_PRINT_NAME.LASER,
            mS.PROMO_PRINT_NAME.LASER_PLUS
        ]},
    deboss: {
        name: "Deboss",
        list: [
            mS.PROMO_PRINT_NAME.DEBOSS
        ]
    },
    embroidery: {
        name: 'Embroidery',
        list: [
            mS.PROMO_PRINT_NAME.EMBROIDERY
        ]
    }
}

function getDiscountedPriceFromCode(code) {
    switch(code) {
        case mS.PROMO_PRODUCT_DISCOUNT_CODES.A:
        case mS.PROMO_PRODUCT_DISCOUNT_CODES.P:
            return 0.50;
        case mS.PROMO_PRODUCT_DISCOUNT_CODES.B:
        case mS.PROMO_PRODUCT_DISCOUNT_CODES.Q:
            return 0.55;
        case mS.PROMO_PRODUCT_DISCOUNT_CODES.C:
        case mS.PROMO_PRODUCT_DISCOUNT_CODES.R:
            return 0.60;
        case mS.PROMO_PRODUCT_DISCOUNT_CODES.D:
        case mS.PROMO_PRODUCT_DISCOUNT_CODES.S:
            return 0.65;
        case mS.PROMO_PRODUCT_DISCOUNT_CODES.E:
        case mS.PROMO_PRODUCT_DISCOUNT_CODES.T:
            return 0.70;
        case mS.PROMO_PRODUCT_DISCOUNT_CODES.F:
        case mS.PROMO_PRODUCT_DISCOUNT_CODES.U:
            return 0.75;
        case mS.PROMO_PRODUCT_DISCOUNT_CODES.G:
        case mS.PROMO_PRODUCT_DISCOUNT_CODES.V:
            return 0.80;
        case mS.PROMO_PRODUCT_DISCOUNT_CODES.H:
        case mS.PROMO_PRODUCT_DISCOUNT_CODES.W:
            return 0.85;
        case mS.PROMO_PRODUCT_DISCOUNT_CODES.I:
        case mS.PROMO_PRODUCT_DISCOUNT_CODES.X:
            return 0.90;
        case mS.PROMO_PRODUCT_DISCOUNT_CODES.NET:
            return 1;
        default:
            return 1;
    }
}

var promoProductSetupFees = {
    code: "G",
    default: {
        single: 68.75,
        multi: 118.75
    },
    deboss: {
        single: 93.75,
        multi: 93.75
    },
    laser: {
        single: 68.75,
        multi: 68.75
    },
    embroidery: {
        single: 68.75,
        multi: 68.75
    }

    // lpp: {
    //     laser: {
    //         single: 68.75,
    //         multi: 118.75
    //     },
    //     screenPrint: {
    //         single: 68.75,
    //         multi: 118.75
    //     }
    // },
    // bpp: {
    //     laser: {
    //         single: 68.75,
    //         multi: 118.75
    //     },
    //     screenPrint: {
    //         single: 68.75,
    //         multi: 118.75
    //     }
    // }
}

var promoProductRunCharges = {
    code: "G",
    lpp: {
        print: {
            // todo digital
            screenprint: {
                firstColourIncluded: true,
                runChargeType: mS.PROMO_PRODUCT_RUNCHARGE_TYPE.PER_COLOUR,
                pricePerColour: 1.00
            },
            padprint: {
                firstColourIncluded: true,
                runChargeType: mS.PROMO_PRODUCT_RUNCHARGE_TYPE.PER_COLOUR,
                pricePerColour: 1.00
            }
        },
        laser: {
            laser: {
                firstColourIncluded: true,
                runChargeType: mS.PROMO_PRODUCT_RUNCHARGE_TYPE.STATIC,
                staticPrice: 1.00
            },
            laserplus: {
                firstColourIncluded: false,
                runChargeType: mS.PROMO_PRODUCT_RUNCHARGE_TYPE.STATIC,
                staticPrice: 1.25
            }
        },
        deboss: {
            deboss: {
                firstColourIncluded: true,
                runChargeType: mS.PROMO_PRODUCT_RUNCHARGE_TYPE.STATIC,
                staticPrice: 1.00
            }
        },
        embroidery: {
            embroidery: {
                firstColourIncluded: false,
                runChargeType: mS.PROMO_PRODUCT_RUNCHARGE_TYPE.STATIC,
                staticPrice: 3.13
            }
        }
    },
    bpp: {
        print: {
            // todo digital
            screenprint: {
                firstColourIncluded: true,
                runChargeType: mS.PROMO_PRODUCT_RUNCHARGE_TYPE.PER_COLOUR,
                pricePerColour: 0.48
            },
            padprint: {
                firstColourIncluded: true,
                runChargeType: mS.PROMO_PRODUCT_RUNCHARGE_TYPE.PER_COLOUR,
                pricePerColour: 0.48
            }
        },
        // none for laser
        laser: {
            laser: {
                firstColourIncluded: true,
                runChargeType: mS.PROMO_PRODUCT_RUNCHARGE_TYPE.STATIC,
                staticPrice: 0.48
            },
            laserplus: { // uses leads
                firstColourIncluded: false,
                runChargeType: mS.PROMO_PRODUCT_RUNCHARGE_TYPE.STATIC,
                staticPrice: 1.25
            }
        },
        deboss: {
            deboss: { //uses leeds
                firstColourIncluded: true,
                runChargeType: mS.PROMO_PRODUCT_RUNCHARGE_TYPE.STATIC,
                staticPrice: 1.00
            }
        },
        embroidery: {
            embroidery: { // uses leeds
                firstColourIncluded: false,
                runChargeType: mS.PROMO_PRODUCT_RUNCHARGE_TYPE.STATIC,
                staticPrice: 3.13
            }
        }
    }
}

var products;
// todo cacheproducts dependency workaround
var getProductCache = function() {
    if (products) {
        return products
    } else {
        products = getProducts();
        return products;
    }
}

isASignageProduct = function(product) {
    if (product) {
        return product.type == "Sticker"
    } else {
        return false
    }
}

    isAPatchProduct = function(product){
        if (product){
            return product.type == "Patch"
        } else {
            return false
        }
    }




function createPrint(type,location,numberOfColours,width,height, forceDtg, forceScreenPrint, forceVinyl,forceDft, screenPrintOptions){
    return{
        printType: type,
        forceDtg: forceDtg,
        forceDft: forceDft,
        forceScreenPrint: forceScreenPrint,
        forceVinyl : forceVinyl,
        location: location,
        numberOfColours: numberOfColours,
        width: width,
        height: height,
        screenPrintOptions :screenPrintOptions
    }
}

function createEmbroidery(type,location,width,height, applique, appliqueColours, patch, puff, subpatch, leather,leatherlabel, wovenlabel,wovenpatch){
    return{
        printType: type,
        location: location,
        width: width,
        height: height,
        puff : puff,
        applique : applique,
        appliqueColours : appliqueColours,
        patch : patch,
        subpatch:subpatch,
        leather : leather,
        leatherlabel : leatherlabel,
        wovenlabel : wovenlabel,
        wovenpatch : wovenpatch
    }
}

// to treat print types as 'Print' for suggestion calculations
function allPrintsToCalculate(allPrints){
    var data = []
    _.each(allPrints,function(location, index){
        var nameToUse = location.name ? location.name : index;
        if(location.printColours){
            data.push(createPrint("Print", nameToUse ,Number(location.printColours),location.width, location.height, location.forceDtg, location.forceScreenPrint, location.forceVinyl, location.forceDft, location.screenPrintOptions))
        }
        if (location.embroidery && location.embroidery.length > 0){
            _.each(location.embroidery, function(emb){
                data.push(createEmbroidery("Embroidery",nameToUse ,Number(emb.width),Number(emb.height),  emb.applique, emb.appliqueColours, emb.patch, emb.puff, emb.subpatch, emb.leather, emb.leatherlabel,emb.wovenlabel, emb.wovenpatch))
            })
        }
    })
    return data
}

var taxRate = {
    ab : {
        gst : 0.05,
        pst: 0
    },
    bc : {
        gst : 0.05,
        pst : 0.07
    },
    nl : {
        gst : 0.15,
        pst: 0
    },
    mb : {
        gst : 0.05,
        pst: 0
    },
    nb : {
        gst : 0.15,
        pst: 0
    },
    ns : {
        gst : 0.15,
        pst: 0
    },
    nu : {
        gst : 0.05,
        pst: 0
    },
    nt : {
        gst : 0.05,
        pst: 0
    },
    on : {
        gst : 0.13,
        pst: 0
    },
    pe : {
        gst : 0.15,
        pst: 0
    },
    qc : {
        gst : 0.05,
        pst: 0
    },
    sk : {
        gst : 0.05,
        pst: 0
    },
    yt : {
        gst : 0.05,
        pst: 0
    },
    notax : {
        gst : 0,
        pst: 0
    }
}

function getCanadianTaxRate (order, taxableAmount, province){
    function applyTaxRate(taxRate, order){
        if (taxRate.gst && !order.gstExempt){
            order.gst = taxRate.gst * taxableAmount
        } else {
            order.gst = 0
        }

        if (taxRate.pst && !order.pstExempt){
            order.pst = taxRate.pst * taxableAmount
        } else {
            order.pst = 0
        }
    }

    if (order.shippingType == "Pick Up" ){
        if (order.pickUpLocation == "VANCOUVER"){
            var bcTaxRate = taxRate["bc"]
            applyTaxRate(bcTaxRate, order)
        } else if (order.pickUpLocation == "TORONTO"){
            var onTaxRate = taxRate["on"]
            applyTaxRate(onTaxRate, order)
        }
    } else if (order.shippingType == "Ship" || order.shippingType == "Split Ship"){
        if (order.shippingAddress.country.toLowerCase() == "ca"){
            province = province.toLowerCase().trim()
            var stateTaxRate = taxRate[province]
            if (stateTaxRate) {
                applyTaxRate(stateTaxRate, order)
            } else {
                applyTaxRate(taxRate["notax"],order)
            }
        } else {
            applyTaxRate(taxRate["notax"],order)
        }
    } else {
        applyTaxRate(taxRate["notax"],order)
    }
}

function onlyTransfersAllowed (category){
    return !category.printAllowed.screenPrint  && category.printAllowed.transfer
}

function onlyEmbroideryAllowed(category){
    if(category && category.printAllowed && !category.printAllowed.screenPrint && !category.printAllowed.transfer){
        return true
    } else {
        return false
    }
}

function createDummyItemLine(product, quantity) {
    return {
        details: {
            colourName: product.colours[0].name,
            size: [product.sizes[0]],
            quantity: [quantity]
        },
        print: [],
        customization: {},
        price: 0,
        totalQty: quantity,
        sku: product.sku,
        productName: product.name,
        printSupplier: 'In House'
    }
}

function calculateAveragePricePromo(product, quantity) {
    // get cheapest print method available for the promotional product
    var prices = _.map(product.promoPrintMethods, function(printMethod) {
        var itemLine = createDummyItemLine(product, quantity);
        itemLine.print.push({
            name: 'Test',
            printType: printMethod,
            printColours: 1,
            embroidery: []
        });
        var calculationObject = recalculateCart([itemLine], [product]);
        return {
            printMethod: printMethod,
            error : calculationObject.error,
            quantity: quantity,
            price: itemLine.price
        };
    })
    var sortedPrices = _.sortBy(prices, function(priceObject) {
        return priceObject.price;
    })

    return {
        price : sortedPrices[0].price,
        description : "For a standard print on one location.",
        error :sortedPrices[0].error
    }
}

function createStickerPrint(width, height) {
    return {
        name: 'Sticker Print',
        width: width,
        height: height,
        printType: "Vinyl",
        printColours: 30,
        forceVinyl: true,
        embroidery: []
    }
}

function createPatchPrint(sku, width, height) {
    var print = [];
    if (sku === "PATCH"){
        print = [{
            name : "Patch",
            printType: "Embroidery",
            pmsColours: [],
            embroidery :[{width:width, height:height, patch: true}]
        }]
    } else if (sku === "SUBPATCH"){
        print = [{
            name : "Patch",
            printType: "Embroidery",
            pmsColours: [],
            embroidery :[{width:width, height:height, subpatch: true}]
        }]
    } else if (sku === "LEATHERPATCH"){
        print = [{
            name : "Patch",
            printType: "Embroidery",
            pmsColours: [],
            embroidery :[{width:width, height:height, leather: true}]
        }]
    } else if (sku === "WOVENPATCH"){
        print = [{
            name : "Patch",
            printType: "Embroidery",
            pmsColours: [],
            embroidery :[{width:width, height:height, wovenpatch: true}]
        }]
    }

    return print;
}

function calculateAveragePrice(product, quantity, category) {
    if (!product){
        return
    }

    if (product.type == "Promotional Product") {
        return calculateAveragePricePromo(product, quantity);
    } else {
        var description
        var price
        var error
        var itemLines = [createDummyItemLine(product, quantity)];

        if (product.type == "Sticker") {
            itemLines[0].print.push(createStickerPrint(3, 3))
            description = "For a 3 by 3 inch sticker."
        } else if(product.category == "Patches") {
            itemLines[0].print = createPatchPrint(product.sku, 3, 3);
            description = "For a 3 by 3 inch patch."
        } else if (product.category == "Sublimation") {
            description = "For a full colour product."

        } else if (onlyEmbroideryAllowed(category)) {
            description = "For a standard sized embroidery on one location."
            itemLines[0].print.push({
                name: "front",
                embroidery: [{}]
            })
        } else {
            description = "For a one colour print on one location."
            itemLines[0].print.push({
                name: "front",
                printColours: 1,
                embroidery: []
            })
        }
        var details = recalculateCart(itemLines, [product])
        error = details.error
        price = itemLines[0].price

        return {
            price : price,
            description : description,
            error : error
        }
    }


}

/**
 *
 * @param width
 * @param height
 * @param numberOfColours
 * @param totalQty
 * @param complexity - a number from 1 - 2, 2 should be the maximum you can "multiply" the design by. Customer service reps judgement on how complex artwork is.
 */

function checkMeasurementAndRoundAccuracy(measurement,height){
    if (_.isUndefined(measurement) || _.isNaN(measurement) || _.isNull(measurement) || measurement <= 0){
        if (height){
            measurement = 2
        } else {
            measurement = 3
        }

    }
    return Math.ceil(measurement)
}

function dftPrintC(qty, product, type, width, height){
    //https://docs.google.com/spreadsheets/d/1TJWZCPCw7Wni0lvbJv1GTDF8TNczC4YrKE-5sMxUUag/edit#gid=0
    width = checkMeasurementAndRoundAccuracy(width)
    height = checkMeasurementAndRoundAccuracy(height, true)

    var areaOfPrint = width * height
    var setup = 20.60; //annual increase prone
    var consumablesOfFullFront = 2.06 //annual increase prone
    var areaOfFullfront = 12 * 12
    var printCost = pressCost + (areaOfPrint / areaOfFullfront) * consumablesOfFullFront
    var variableCost = printCost + setup/qty
    // console.log("DTF COST : " + variableCost)
    return variableCost
}

function dtgPrintC(qty, product, type, width, height){
    width = checkMeasurementAndRoundAccuracy(width)
    height = checkMeasurementAndRoundAccuracy(height, true)

    var areaOfPrint = width * height
    var setup = 5.61; //annual increase prone
    var pT
    var ppI
    var bC;


    type = "black"


    if (type.toLowerCase() == "white"){
        bC = 5.00  //$3.32 -> $4.00 (Labour Increase) Feb 14th 2021
        pT = 1.5
        ppI = 0.02
    } else {
        bC = 5.00 //$3.32 -> $4.00 (Labour Increase) Feb 14th 2021
        pT = 1.5
        ppI = 0.03 * 0.8 // average 80% coverage on an order
    }
    //https://docs.google.com/spreadsheets/d/1NtnInO4wHNzDjilfZd86T0x8OmHD5Lujo-y8MC8F4fU/edit#gid=0
    var printCost = bC + (areaOfPrint * ppI) + pT
    return printCost + setup/qty
}

function qtyCategory(qty) {
    var QTYCAT = 0;
    if (qty >= 1 && qty <= 49) {
        QTYCAT = 1;
    }
    if (qty >= 50 && qty <= 99) {
        QTYCAT = 2;
    }
    if (qty >= 100 && qty <= 299) {
        QTYCAT = 3;
    }
    if (qty >= 300 && qty <= 599) {
        QTYCAT = 4;
    }
    if (qty >= 600) {
        QTYCAT = 5;
    }
    return QTYCAT;
}

//type: deprecated
function calculateScreenPrint(numberOfColours, type, numberOfPrints, screenPrintOptions) {
    if (numberOfColours == 0) {
        return {
            runCharge: 0,
            fixedCost: 0
        }
    }

    var printType = mS.PRINT_TYPES.SCREEN_PRINT;
    var runCharge = 0;
    var fixedCost = 0;
    var filmAndScreen = 20.60; //annual increase prone

    if (numberOfPrints){
    } else {
        numberOfPrints = 1
    }

    var designFee = 30.9 / numberOfPrints; //annual increase prone
    numberOfColours = numberOfColours + 1

    runCharge = (0.95) + (numberOfColours * 0.32); //annual increase prone
    fixedCost = ((numberOfColours) * filmAndScreen) + designFee;

    if (screenPrintOptions){
        if(screenPrintOptions.puff){
            var puffCharge = 3 //annual increase prone
            runCharge = runCharge + ( (numberOfColours - 1) * puffCharge)
            printType = mS.SPECIALTY_PRINTS.PUFF_PRINT;
        } else if (screenPrintOptions.spotProcess){
            var spotProcessSurcharge = 1.2
            var spotProcessSetupsSurcharge = 2
            runCharge = runCharge * spotProcessSurcharge //20% more for spot process.
            fixedCost = fixedCost * spotProcessSetupsSurcharge
            printType = mS.SPECIALTY_PRINTS.SPOT_PROCESS;
        }
    }
    return  {
        printType: printType,
        runCharge: runCharge,
        fixedCost: fixedCost
    }
}

function calculateApplique (numberOfColours){
    return {
        printType: mS.SPECIALTY_PRINTS.APPLIQUE,
        runCharge: numberOfColours * 10.3 ,  //annual increase prone
        fixedCost: 56.65,  //annual increase prone
        errors: []
    }
}

function calculateWovenLabel(width, height, totalQty, wovenlabel, wovenpatch){
//https://docs.google.com/spreadsheets/d/1Pnsrw6L7YS_hKHKKwjJC4kb9WMiq_vD5qA3VkYaG624/edit#gid=0
    var applicationFee
    var errors = [];
    var printType;
    if(wovenlabel){
        printType = mS.SPECIALTY_PRINTS.WOVEN_LABEL;
        applicationFee = sewnOnTagsCost
    } else if (wovenpatch) {
        printType = mS.SPECIALTY_PRINTS.WOVEN_PATCH;
        applicationFee = pressCost
    } else {
        // default
        printType = mS.SPECIALTY_PRINTS.WOVEN_PATCH;
        applicationFee = pressCost
    }
    var labelcost = 0
    var setupCost = 25.75 //annual increase prone
    var grid
    var minOrder = 25

    if (height > 3.5 || width > 3.5) {
        errors.push({
            message: 'Max dimensions for a woven patch is 3.5 inches',
            showModal: true
        })
    }

    if (totalQty < 50){
        grid = [
            {size : 1 , price : 5.17},
            {size : 1.5 , price : 5.33},
            {size : 2 , price : 6.34},
            {size : 2.5 , price : 7.30},
            {size : 3 , price : 7.46},
            {size : 3.5 , price : 11.79}
        ]
    } else if (totalQty >= 50 && totalQty < 100){
        grid = [
            {size : 1 , price : 3.36},
            {size : 1.5 , price : 4.16},
            {size : 2 , price : 5.23},
            {size : 2.5 , price : 5.33},
            {size : 3 , price : 6.02},
            {size : 3.5 , price : 7.30}
        ]
    } else if (totalQty >= 100  && totalQty < 200){
        grid = [
            {size : 1 , price : 1.94},
            {size : 1.5 , price : 2.16},
            {size : 2 , price : 2.75},
            {size : 2.5 , price : 2.90},
            {size : 3 , price : 3.17},
            {size : 3.5 , price : 3.70}
        ]
    } else if (totalQty >= 200 && totalQty < 500 ){
        grid = [
            {size : 1 , price : 1.33},
            {size : 1.5 , price : 1.42},
            {size : 2 , price : 1.58},
            {size : 2.5 , price : 1.70},
            {size : 3 , price : 1.79},
            {size : 3.5 , price : 1.97}
        ]
    } else if (totalQty >= 500 && totalQty < 1000){
        grid = [
            {size : 1 , price : 0.83},
            {size : 1.5 , price : 0.88},
            {size : 2 , price : 0.99},
            {size : 2.5 , price : 1.02},
            {size : 3 , price : 1.09},
            {size : 3.5 , price : 1.18}
        ]
    } else if (totalQty >= 1000) {
        grid = [
            {size : 1 , price : 0.53},
            {size : 1.5 , price : 0.54},
            {size : 2 , price : 0.62},
            {size : 2.5 , price : 0.64},
            {size : 3 , price : 0.67},
            {size : 3.5 , price : 0.69}
        ]
    }

    var measurementDevice = (width + height) / 2
    var correctGrid = _.find(grid, function(object){
        return     measurementDevice <= object.size
    })
    if (correctGrid) {
        labelcost = correctGrid.price
    }

    if (labelcost <= 0 || _.isUndefined(labelcost) || _.isNull(labelcost)){
        labelcost = 0
    }

    if (totalQty < minOrder){
        labelcost = labelcost * minOrder / totalQty
    }

    return {
        printType: printType,
        runCharge: labelcost + applicationFee,
        fixedCost: setupCost,
        errors: errors
    }
}

function calculateEmbroidery(options) {

    var width = options.width;
    var height = options.height;
    var totalQty = options.totalQty;
    var patch = options.patch;
    var puff = options.puff;
    var subpatch = options.subpatch;
    var leather = options.leather;
    var leatherlabel = options.leatherlabel;
    var wovenlabel = options.wovenlabel;
    var wovenpatch = options.wovenpatch;

    var printType = mS.PRINT_TYPES.EMBROIDERY; // default
    var roundedWidth = checkMeasurementAndRoundAccuracy(width)
    var roundedHeight = checkMeasurementAndRoundAccuracy(height, true)
    var standardLeftChestStitchCount = 15000
    var embroideryLoadingCost = 2.83 //annual increase prone
    var costForStandardLeftChest = 1.6995 //annual increase prone
    var leftChestArea = 8 // 4 inches wide by 2 inches tall.
    var areaOfDesign = roundedWidth * roundedHeight
    var minStitchCount = 10000 // it costs money to setup the embroidery design
    var maxStitchCount = 50000 //andrew chus designs are around 40,000 stitch count and thats rare.
    var fixedCost = 0;
    var maxSetupFee = 79.31 //annual increase prone
    var fixedCostPerThousandStitchCount = 3.965 //annual increase prone
    //4 by 2 inches is 7000 stitches
    var stitchCount = Math.max((areaOfDesign / leftChestArea) * standardLeftChestStitchCount, minStitchCount ); //min stitch count is 5000
    stitchCount = Math.min(stitchCount, maxStitchCount)  //max stitch count is 70,000
    fixedCost = ((stitchCount / 1000) * fixedCostPerThousandStitchCount); //
    fixedCost = Math.min(maxSetupFee, fixedCost); //max fixed cost is 100 for embroidery
    var runCharge = (costForStandardLeftChest * (stitchCount/standardLeftChestStitchCount)) + embroideryLoadingCost
    var patchSurcharge = 0
    var puffSurcharge = 0
    if (patch || subpatch || leather || leatherlabel){
        if (patch) {
            printType = mS.SPECIALTY_PRINTS.EMBROIDERED_PATCH;
        }
        if (subpatch) {
            printType = mS.SPECIALTY_PRINTS.SUBLIMATED_PATCH;
        }
        if (leather) {
            printType = mS.SPECIALTY_PRINTS.LEATHER_PATCH;
        }
        if (leatherlabel) {
            printType = mS.SPECIALTY_PRINTS.LEATHER_LABEL;
        }
        patchSurcharge = 3.605  //annual increase prone
    }

    if (wovenlabel || wovenpatch){
        return calculateWovenLabel(width, height, totalQty, wovenlabel, wovenpatch)
    }

    if (puff){
        printType = mS.SPECIALTY_PRINTS.PUFF_EMBROIDERY;
        puffSurcharge = 3.09 //annual increase prone
    }
    return {
        printType: printType,
        runCharge: runCharge + patchSurcharge + puffSurcharge,
        fixedCost: fixedCost,
        errors: [],
    }
}



//// selections: [name,colourType,qtyBreakdown,name,price]
function calculateSublimationPrice(selection, product){
    var indexOf2XL = product.sizes.indexOf("2XL");
    var garmentTotals = calculateGarmentCost(selection, indexOf2XL, product);
    var totalQty = garmentTotals.totalQty
    var garmentCost = garmentTotals.garmentCost + product.setupCost
    var errors = [];

    if (product && product.minimumOrder && (totalQty <  product.minimumOrder) ){
        var errorMsg = product.name + " has a minimum order of " + product.minimumOrder + " units. Please add more units than the minimum order."
        errors.push({
            message: errorMsg
        })
    }

    var sublimationSetupCost = 20.60  //annual increase prone
    var sublimationMarkup = 1.8975

    return {
        totalQty: totalQty,
        totalSetup : 0,
        totalCost: (sublimationMarkup * (garmentCost + sublimationSetupCost)).toFixed(2),
        errors: errors,
    }
}

/**
 *   Calculate promo pricing. Consists of:
 *      1) Product Price, which is pricing matrix metadata found from the product itself
 *      2) Run Charge, depending on which print was selected --> object found in promoProductRunCharges
 *      3) Setup Charge, depending on which print was selected --> found in promoProductSetupFees
 *
 * @param product
 * @param selection //[name,colourType,qtyBreakdown,name,price]
 * @param allPrints
 */
function calculatePromoProductPrice(product, selection, allPrints) {
    var productCost = 0;
    var totalQty = 0;
    var totalSetup = 0;
    var runCharge = 0;

    for (var a = 0; a < selection[2].length; a++) {
        totalQty += selection[2][a];
    }

    let foundColour = _.find(product.colours, function(colour) {
        return colour.name === selection[3];
    });

    // Validation

    // 1) if any print location has more than max colours, throw
    _.forEach(allPrints, function(print) {
        var maxPrintColours = 4;
        if (print.printType === mS.PROMO_PRINT_NAME.EMBROIDERY) {
            maxPrintColours = 12;
        }
        if (print.printColours > maxPrintColours) {
            throw Error(print.name + ' location contains ' +  print.printColours + ' colours which exceeds the limit of ' + maxPrintColours + '. Please reduce the number of colours in your print.');
        }
    });
    if (_.isEmpty(foundColour)) {
        throw Error('Color was not found');
    }

    var priceMatrix;
    if (_.isEmpty(foundColour.customPrice)) {
        throw Error('Color is missing a pricing table');
    } else {
        priceMatrix = foundColour.customPrice.priceThresholds;
    }

    var belowMinOrderMarkup = 0;
    var columnIndex = _.findLastIndex(priceMatrix, function (priceThreshold) {
        return totalQty >= priceThreshold.qty;
    });
    if (columnIndex === -1) {
        //Add flat min fee if less than first column, then treat as first qty column
        belowMinOrderMarkup += PCNAPromoProductMinimumOrderFlatFee
        columnIndex = 0;
    }

    var matchingPriceThreshold = priceMatrix[columnIndex];
    var quantityPricing = matchingPriceThreshold.cost;
    // lpp bpp EQP
    if (product.sCode === 'lpp' || product.sCode === 'bpp') {
        quantityPricing = priceMatrix[priceMatrix.length - 1].cost;
    }

    // Product cost
    productCost = quantityPricing * totalQty * getDiscountedPriceFromCode(matchingPriceThreshold.pricingCode);

    // Setup + run charge costs
    // todo: print Suggestions do not recommend anything atm
    var printSuggestions = [];
    _.forEach(allPrints, function(print, printLocationIndex) {
        var decorationMethodCosts = calculateDecorationMethodCost(product, print, columnIndex, printLocationIndex)
        addPrintSuggestion(printSuggestions, print.name, decorationMethodCosts.printType, printLocationIndex);
        runCharge += decorationMethodCosts.runCost * totalQty;
        totalSetup += decorationMethodCosts.setUpCost;
    })

    // console.log('Markup: '+ markup);
    var promoMarkup = getPromoProductMarkup(totalQty);
    var chargePrice = (((productCost + runCharge + totalSetup + belowMinOrderMarkup) * promoMarkup * PCNAShippingMarkup) + PCNAPromoProductRushProductionFee);
    return {
        totalQty: totalQty,
        // do not have reorder discount for promo products
        totalSetup : 0,
        totalCost: chargePrice.toFixed(2),
        printSuggestions : printSuggestions
    }
}

/* for contract sku */
function calculatePromoProductNotOnSiteCost(promotionalPricingData, totalQty) {
    var currency = promotionalPricingData.currency ? promotionalPricingData.currency : 'CAD';
    var price = promotionalPricingData.price ? promotionalPricingData.price : 0;
    var priceCode = promotionalPricingData.priceCode ? promotionalPricingData.priceCode : "";
    var runCharge = promotionalPricingData.runCharge ? promotionalPricingData.runCharge : 0;
    var runChargeCode = promotionalPricingData.runChargeCode ? promotionalPricingData.runChargeCode : "";
    var setUpCost = promotionalPricingData.setUpCost? promotionalPricingData.setUpCost : 0;
    var setUpCostCode = promotionalPricingData.setUpCostCode ? promotionalPricingData.setUpCostCode : 0;
    var shippingCost = promotionalPricingData.shippingCost ? promotionalPricingData.shippingCost : 0;

    var totalCost = ((runCharge * (getDiscountedPriceFromCode(runChargeCode)) + price * (getDiscountedPriceFromCode(priceCode))) * totalQty) + (setUpCost * (getDiscountedPriceFromCode(setUpCostCode)));

    // duties
    if (currency === 'USD') {
        var percentDuties = promotionalPricingData.percentageDuties ? promotionalPricingData.percentageDuties : 0;
        var fixedDuties = promotionalPricingData.duties ? promotionalPricingData.duties: 0;

        if (promotionalPricingData.isFixedDuties) {
            totalCost += fixedDuties;
        } else {
            totalCost += (totalCost * (percentDuties / 100))
        }
    }

    return totalCost + shippingCost
}

function calculatePromoProductNotOnSitePrice(promotionalPricingData, totalQty) {
    var totalCost = calculatePromoProductNotOnSiteCost(promotionalPricingData, totalQty);
    var currencyConversion = promotionalPricingData.currency === 'USD' ? USDToCADExchange : 1;
    var promoMarkup = getPromoProductMarkup(totalQty);
    var chargePrice = totalCost * promoMarkup * currencyConversion;

    return {
        totalQty: totalQty,
        totalSetup : 0,
        totalCost: (chargePrice).toFixed(2),
        errors: []
    }
}

/**
 * Calculate setup and print fees for a given decoration method.
 * @param product
 * @param print
 * @param qtyColumnIndex
 * @param printLocationIndex
 * @return
 */
function calculateDecorationMethodCost(product, print, qtyColumnIndex, printLocationIndex) {
    var determinedPrintType;
    // If from public entry we need to switch from print group to to a specific print type
    var promoProductPrintGroup = promoProductDecorationGrouping[print.printType.toLowerCase()];
    if (promoProductPrintGroup) {
        if (!product.promoPrintMethods) {
            throw new Error('Product is not configured with any print methods');
        }
        // this is actually a print group, not a specific type --> return a default type
        var specificPrintType = _.find(promoProductPrintGroup.list, function (printType) {
            return product.promoPrintMethods.includes(printType);
        })
        if (!specificPrintType) {
            throw new Error('Product is not configured to be printed with ' + promoProductPrintGroup.name);
        }
        determinedPrintType = specificPrintType;
    } else {
        determinedPrintType = print.printType;
    }

    if (!product.promoPrintMethods.includes(determinedPrintType)) {
        throw new Error('Product is not configured to be printed with ' + determinedPrintType);
    }

    var supplierCode;
    if (product.sCode) {
        supplierCode = product.sCode;
    } else {
        // default to leeds
        supplierCode = 'lpp';
    }

    // multi or single
    var multiColour;
    if (print.printColours > 1) {
        multiColour = 'multi'
    } else {
        multiColour = 'single'
    }

    // get decoration group key
    var printGroupKey = _.findKey(promoProductDecorationGrouping, function (printMethodGroup) {
        return printMethodGroup.list.includes(determinedPrintType);
    });

    var setupMatrix = promoProductSetupFees[printGroupKey];
    if (!setupMatrix) {
        setupMatrix = promoProductSetupFees.default;
    }

    var setupCost = setupMatrix[multiColour] * getDiscountedPriceFromCode(promoProductSetupFees.code);
    var supplierRunCharge = promoProductRunCharges[supplierCode]
    if (!supplierRunCharge) {
        console.log('Promo Product: ' + product.sku + ' has unhandled supplier code ' + product.sCode);
        supplierRunCharge = promoProductRunCharges.lpp;
    }

    var printGroupRunCharge = supplierRunCharge[printGroupKey]
    if (!printGroupRunCharge) {
        throw new Error('Supplier: ' + supplierCode + ' is not configured to be printed with group ' + printGroupKey);
    }

    var printRunCharge = printGroupRunCharge[determinedPrintType.replace(/\s/g,'').toLowerCase()];
    if (!printRunCharge) {
        throw new Error('Supplier: ' + supplierCode + ' is not configured to be printed with ' + determinedPrintType);
    }

    var firstLocation = 0;
    if (printLocationIndex === 0) {
        firstLocation = 1;
    }

    var runCharge = 0;
    switch(printRunCharge.runChargeType) {
        case mS.PROMO_PRODUCT_RUNCHARGE_TYPE.PER_COLOUR:
            if (printRunCharge.firstColourIncluded && firstLocation && print.printColours === 1) {
                runCharge = 0;
            } else {
                runCharge = printRunCharge.pricePerColour * (firstLocation ? (print.printColours - 1): print.printColours);
            }
            break;
        case mS.PROMO_PRODUCT_RUNCHARGE_TYPE.STATIC:
            if (printRunCharge.firstColourIncluded && firstLocation) {
                runCharge = 0;
            } else {
                runCharge = printRunCharge.staticPrice;
            }
            break;
        // todo:
        case mS.PROMO_PRODUCT_RUNCHARGE_TYPE.STEP:
        default:
            throw new Error('Supplier: ' + supplierCode + ' Print type: ' + determinedPrintType + ' run charge type ' + printRunCharge.type + ' is not configured');
    }
    var runCost = runCharge * getDiscountedPriceFromCode(promoProductRunCharges.code);
    // console.log('Run Charge Net: ' + runCost);
    return {
        printType: determinedPrintType,
        setUpCost: setupCost,
        runCost: runCost
    }
}

/**
 * Return percentage markup for promo. Sliding scale starting at 165% --> 148.5% from 100 units to 500 units
 * @param quantity
 */

function getPromoProductMarkup(quantity) {
    var lowestMarkup = 1.40
    const priceRanges = [
        { startQty: 0, finishQty: 100, startP: 1.65, finishP: 1.65 },
        { startQty: 100, finishQty: 500, startP: 1.65, finishP: lowestMarkup }
    ];
    for (const range of priceRanges) {
        if (quantity >= range.startQty && quantity <= range.finishQty) {
            const m = range.startP - ((range.startP - range.finishP) / (range.finishQty - range.startQty)) * (quantity - range.startQty);
            return m;
        }
    }
    return lowestMarkup
}

function seeIfMisMatchSupplierAndProduct(product, supplier){
    return (supplier && supplier.currency && (supplier.currency.toLowerCase() == "usd"))
        &&
        (product && product.countryOrigin && (product.countryOrigin.toLowerCase() == "ca"))
}

function calculateGarmentCost(selection, indexOf2XL, product, itemLine, suppliers, itemLinesInGroup) {
    var garmentCost = 0;
    var totalQty = 0;
    for (var a = 0; a < selection[2].length; a++) {
        totalQty = totalQty + selection[2][a];
        garmentCost = garmentCost + selection[2][a] * selection [4];
        if (selection[2][a] && indexOf2XL > 2){
            var additionalSizePrice = 0;
            if (a >= indexOf2XL) {
                additionalSizePrice = product.sizePricing.doubleXL * selection[2][a]
            }
            if (a > indexOf2XL && product.sizePricing.tripleXL) {
                additionalSizePrice = product.sizePricing.tripleXL * selection[2][a]
            }
            garmentCost = garmentCost + additionalSizePrice;
        }
    }

    if (product && product.category && product.category.toLowerCase() == "masks"){
        // mask markup
        garmentCost = garmentCost + 1;
    }

    if (product && product.countryOrigin && (product.countryOrigin.toLowerCase() == "us")){
        garmentCost = garmentCost * dutiesAndTaxMarkup * USDToCADExchange //duties and taxes ~1.67, make sure this is the same in order places
    }

    if (itemLine && suppliers){
        var supplier = _.find(suppliers, function(sup){
            return sup.name == itemLine.garmentSupplier
        })
        if (
            seeIfMisMatchSupplierAndProduct(product, supplier)
        ){
            garmentCost = (garmentCost * dutiesAndTaxMarkup) + (borderDutiesFixed/itemLinesInGroup) //40 is the fixed cost to get someting over the border.
        }

    }

    return {
        garmentCost: garmentCost,
        totalQty: totalQty
    };
}

function getAmountWithinRange(startP, startQty, finishP, finishQty, quantity){
    var m
    if (quantity > finishQty){
        m = finishP
    } else if (quantity <= startQty) {
        m = startP
    } else {
        m = startP - (((startP - finishP)/(finishQty - startQty)) * (quantity - startQty))
    }
    return m
}

function getMGarment(priceOfItem, quantity){
    var startP = 1.947
    var startPrice = 15

    var secondP = 1.6938
    var secondTierPrice = 26

    var thirdP = 1.50
    var thirdTierPrice = 100

    var m
    if (priceOfItem < startPrice){
        m = startP
    } else if (priceOfItem >= startPrice && priceOfItem <= secondTierPrice){
        m = getAmountWithinRange(startP,startPrice, secondP,secondTierPrice,priceOfItem)
    } else if (priceOfItem >= secondTierPrice && priceOfItem <= thirdTierPrice) {
        m = getAmountWithinRange(secondP,secondTierPrice, thirdP, thirdTierPrice, priceOfItem)
    } else if (priceOfItem > thirdTierPrice){
        m = thirdP
    } else {
        m = startP
    }
    return Math.min(m,getM(quantity))
}

function getM(quantity) {
    var lowestMarkup = 1.40
    const priceRanges = [
        { startQty: 0, finishQty: 24, startP: 1.947, finishP: 1.947 },
        { startQty: 24, finishQty: 100, startP: 1.947, finishP: 1.87 },
        { startQty: 100, finishQty: 200, startP: 1.87, finishP: 1.87 },
        { startQty: 200, finishQty: 600, startP: 1.87, finishP: 1.595 },
        { startQty: 600, finishQty: 1000, startP: 1.595, finishP: 1.595 },
        { startQty: 1000, finishQty: 3000, startP: 1.595, finishP: lowestMarkup}
    ];
    for (const range of priceRanges) {
        if (quantity >= range.startQty && quantity <= range.finishQty) {
            const m = range.startP - ((range.startP - range.finishP) / (range.finishQty - range.startQty)) * (quantity - range.startQty);
            return m;
        }
    }
    return lowestMarkup
}

//selections: //[name,colourType,qtyBreakdown,name,price]
calculateStickerPricing = function(product, allPrints, selections){
    // https://docs.google.com/spreadsheets/d/1CwrRoH9PdZtVjPYcZHG0MiVNkVRrd6NrtDUkqkbv5ts/edit#gid=0
    var variablePriceOfSticker = product.colours[0].price
    var setupCost = product.setupCost
    var area = allPrints[0].width * allPrints[0].height
    var quantity = 0
    var errors = []

    _.each(selections, function (selection) {
        var garmentTotals = calculateGarmentCost(selection, 10000, product)
        quantity = garmentTotals.totalQty + quantity;
    })

    if (area === 0) {
        errors.push({
            message: 'Please enter a width and height greater than 0 for your print.'
        })
    }

    var totalCost = ((((variablePriceOfSticker * area) + 0.0625) * quantity) + setupCost) * 3.27777

    var stickerMarkup = 1.32 //Added September 24th 2022 to increase sticker pricing

    return {
        totalQty: quantity,
        totalSetup : 0,
        totalCost: (totalCost * stickerMarkup).toFixed(2), //
        errors: errors
    }

}

function addPrintSuggestion(printSuggestions, location, printType, printLocationIndex){
    if (printSuggestions) {
        if (!_.find(printSuggestions, function (printSuggestion) {
            return printSuggestion.location == location
                && printSuggestion.printType == printType
                && printSuggestion.printLocationIndex == printLocationIndex
        })) {
            printSuggestions.push({
                location: location,
                printLocationIndex: printLocationIndex,
                printType: printType
            })
        }
    }
}

function addPrintCharge(printChargeArray, location, printType, cost, print) {
    // treat + sum grouped prints as one total charge
    if (printChargeArray) {
        var existingPrintCharge = _.find(printChargeArray, function(printCharge) {
            return printCharge.metadata.location == location && printCharge.printType == printType
        });
        //
        if (!existingPrintCharge) {
            printChargeArray.push({
                printType: printType,
                totalCost: cost,
                metadata: print
            })
        } else {
            existingPrintCharge.totalCost += cost;
        }
    }
}

// selections: [[name,colourType,qtyBreakdown,name,price]]
calculatePrice = function (product, selections, allPrints, numberOfIndividualNames, numberOfIndividualNumbers,totalCsFeeForItem, itemLine, supplier, category) {
    var totalSetup = 0;

    //todo: this assumes 2xl index is always '2xl, does not work for toddler/kid sizes
    var indexOf2XL = product.sizes.indexOf("2XL");
    var garmentCost = 0
    var totalQty = 0
    var errors = [];
    // console.log("All Prints")
    // console.log(allPrints)

    _.each(selections, function (selection) {
        if (_.isNull(selection[4]) || _.isUndefined(selection[4])){
            var missingPrice = "Please enter a price for your product"
            errors.push({
                message: missingPrice,
            })
        }
        var garmentTotals = calculateGarmentCost(selection, indexOf2XL, product, itemLine, supplier, selections.length)
        totalQty = garmentTotals.totalQty + totalQty;
        garmentCost = garmentTotals.garmentCost + garmentCost;
    })

    if (product && product.countryOrigin && (product.countryOrigin.toLowerCase() == "us")){
        garmentCost = garmentCost + borderDutiesFixed //duties and taxes.
    }

    var QTYCAT = qtyCategory(totalQty)
    var embroideryFixedCost = 0
    var embroideryVariableCost = 0

    var printCost  = 0
    var printSuggestions = []
    var printCharges = [];

    // each group
    _.each(selections, function (selection) {
        var quantityForSelection = _.reduce(selection[2], function (memo, num) {
            return memo + num;
        }, 0);
        _.each(allPrints, function (currentPrint, printLocationIndex) {
            var numberOfScreenPrints = _.filter(allPrints, function(print){
                return print.printType == "Print"
            }).length

            if (currentPrint.printType == "Print") {
                var totalDtgPrintCost = 0
                var dtgCostForPrint = (dtgPrintC(totalQty, product,selection[1], currentPrint.width, currentPrint.height) * quantityForSelection);
                totalDtgPrintCost = totalDtgPrintCost + dtgCostForPrint;

                var totalDftPrintCost = 0
                var dtfCostForPrint = (dftPrintC(totalQty, product,selection[1], currentPrint.width, currentPrint.height) * quantityForSelection)
                totalDftPrintCost = totalDftPrintCost + dtfCostForPrint

                var totalScreenPrintCost = 0
                var spCost = calculateScreenPrint(currentPrint.numberOfColours, selection[1], numberOfScreenPrints, currentPrint.screenPrintOptions)
                var spCostForPrint = (spCost.fixedCost * (quantityForSelection/totalQty)) + (spCost.runCharge * quantityForSelection)
                totalScreenPrintCost = totalScreenPrintCost + spCostForPrint
                if (currentPrint.forceDtg){
                    printCost = printCost + totalDtgPrintCost
                    addPrintSuggestion(printSuggestions, currentPrint.location,"DTG", printLocationIndex)
                    addPrintCharge(printCharges, currentPrint.location, "DTG", dtfCostForPrint, currentPrint)
                } else if (currentPrint.forceScreenPrint) {
                    printCost = printCost + totalScreenPrintCost
                    addPrintSuggestion(printSuggestions, currentPrint.location,'Screen Print', printLocationIndex)
                    addPrintCharge(printCharges, currentPrint.location, spCost.printType, spCostForPrint, currentPrint)
                    totalSetup = totalSetup + spCost.fixedCost * (quantityForSelection/totalQty); // DTG has no setup cost.
                } else if (currentPrint.forceDft){
                    printCost = printCost + totalDftPrintCost
                    addPrintSuggestion(printSuggestions, currentPrint.location,"DFT", printLocationIndex)
                    addPrintCharge(printCharges, currentPrint.location, "DFT", dtfCostForPrint, currentPrint)
                }  else {
                    if (currentPrint.numberOfColours > 6 || (category && onlyTransfersAllowed(category))){
                        printCost = printCost + totalDftPrintCost   //02/14/2022 change to DTG, since vinyl was taken over by DTF printing.
                        addPrintSuggestion(printSuggestions, currentPrint.location,"DFT", printLocationIndex) //02/14/2022 change to DTG, since vinyl was taken over by DTF printing.
                        addPrintCharge(printCharges, currentPrint.location, "DFT", dtfCostForPrint, currentPrint)
                    } else {
                        if (totalDftPrintCost < totalScreenPrintCost){ //chooses the option that is the most economical. 20 percent additional has been added on. For instance, if screen printing is like 0.20 cents more than vinyl... we should just do screen print cause the quality is a lot better.
                            printCost = printCost + totalDftPrintCost
                            addPrintSuggestion(printSuggestions, currentPrint.location,"DFT", printLocationIndex) //02/14/2022 change to DTG, since vinyl was taken over by DTF printing.
                            addPrintCharge(printCharges, currentPrint.location, "DFT", dtfCostForPrint, currentPrint)
                        } else {
                            printCost = printCost + totalScreenPrintCost
                            addPrintSuggestion(printSuggestions, currentPrint.location, 'Screen Print', printLocationIndex)
                            addPrintCharge(printCharges, currentPrint.location, spCost.printType, spCostForPrint, currentPrint)
                            totalSetup = totalSetup + spCost.fixedCost * (quantityForSelection/totalQty); // DTG has no setup cost.
                        }
                    }
                }
            }

            if (currentPrint.printType == "Embroidery") {
                if (currentPrint.width <= 0 || currentPrint.height <= 0) {
                    errors.push({
                        message: "Please enter a width and height greater than 0 for your print.",
                    })
                }
                var cost

                if (currentPrint.applique){
                    cost = calculateApplique(currentPrint.appliqueColours)
                } else {
                    cost = calculateEmbroidery({
                        width: currentPrint.width,
                        height: currentPrint.height,
                        totalQty: totalQty,
                        patch: currentPrint.patch,
                        puff: currentPrint.puff,
                        subpatch: currentPrint.subpatch,
                        leather: currentPrint.leather,
                        leatherlabel: currentPrint.leatherlabel,
                        wovenlabel: currentPrint.wovenlabel,
                        wovenpatch: currentPrint.wovenpatch
                    });
                }

                if (cost.errors && cost.errors.length > 0) {
                    errors = errors.concat(cost.errors);
                }

                var printFixedCost = (cost.fixedCost * (quantityForSelection/totalQty));
                var printVariableCost = (cost.runCharge * quantityForSelection);

                totalSetup = totalSetup + printFixedCost
                embroideryFixedCost = embroideryFixedCost + printFixedCost;
                embroideryVariableCost = embroideryVariableCost + printVariableCost
                addPrintCharge(printCharges, currentPrint.location, cost.printType, (printFixedCost + printVariableCost), currentPrint);
            }
        })
    })

    var totalCost =
        (getM(totalQty) * (embroideryFixedCost + embroideryVariableCost + printCost))
        + (getMGarment(garmentCost/totalQty,totalQty) * garmentCost)
        + ((numberOfIndividualNumbers + numberOfIndividualNames) * customizationCostPerUnit)
    return {
        totalQty: totalQty,
        totalSetup : totalSetup,
        totalCost: (totalCost).toFixed(2),
        errors: errors,
        printSuggestions : printSuggestions,
        printCharges: printCharges,
    }
}

function getTotalQtyForItemLine(itemLines){
    return Number(_.reduce(itemLines, function(totalQty, itemLine){
        var quantityOnItemLine = _.reduce(itemLine.details.quantity, function(qty, sizeqty){
            return Number(qty) + Number(sizeqty)
        },0)
        return totalQty + quantityOnItemLine;
    },0))
}

function needsStockSwatchColours(print){
    return print.printType == "Screen Print" || (print.embroidery && print.embroidery.length > 0)
}

function getNumberOfCustomizations(namesAndNumbers) {
    var numberOfNumbers = 0;
    var numberOfNames = 0;

    _.forEach(namesAndNumbers, function(nameAndNumber) {
        if (nameAndNumber.number) {
            numberOfNumbers = numberOfNumbers + 1;
        }
        if (nameAndNumber.name) {
            numberOfNames = numberOfNames + 1;
        }
    });

    return {
        namesCount: numberOfNames,
        numbersCount: numberOfNumbers
    }
}

function recalculateCart(itemLines, products, placedThroughAdmin, suppliers, allCats, isBudgeting){

    var productList;
    if (products) {
        productList = products
    } else {
        productList = getProductCache();
    }

    var fixedAmount = 0;

    var itemGroup = _.groupBy(itemLines, function(item){
        return item.group
    })

    var itemLineChargeBreakdown = []
    var itemLineErrors = [];
    _.each(itemLines, function(item, i){
            var chargeBreakdown = {
                index: i,
                product: null,
                printSupplier: item.printSupplier,
                totalQty: null,
                group: item.group,
                groupQty: null,
                includeShippingCharge: null,
                csrCharge: {
                    printedItemLine: null,
                    totalCost: null,
                },
                printCharges: [],
                customizationCharges: []
            }
            itemLineChargeBreakdown[i] = chargeBreakdown;
            var itemsToLookUp = [item]
            if (item.group){
                itemsToLookUp = itemGroup[item.group]
            }
            var allPrints = allPrintsToCalculate(item.print)

            //totalCsFee
            var totalCsFee = csrFeePrint

            if (allPrints && allPrints.length == 0){
                totalCsFee = csrFeeBlank
                chargeBreakdown.csrCharge.printedItemLine = false;
            } else {
                chargeBreakdown.csrCharge.printedItemLine = true;
            }
            var totalQtyForItemLine = getTotalQtyForItemLine(itemsToLookUp);
            chargeBreakdown.groupQty = totalQtyForItemLine;
            var csFeePerUnit = 0
            if (totalQtyForItemLine > 0) {
                csFeePerUnit = totalCsFee / totalQtyForItemLine;
            }

            var quantity = _.reduce(item.details.quantity, function(sum, quantity){
                return Number(sum) + Number(quantity)
            },0)

            chargeBreakdown.totalQty = quantity;
            chargeBreakdown.csrCharge.totalCost = csFeePerUnit * quantity;

            var product = _.find(productList, function(individualProduct){
                return individualProduct.sku == item.sku
            })

            var currentColour

            if (product){
                currentColour = _.find(product.colours, function(colour){
                    return colour.name == item.details.colourName
                });
            } else if (item.sku === 'custom') {
                var productOrigin = 'CA';
                if (item.customProductPriceCurrency === 'USD') {
                    productOrigin = 'US'
                }
                product = {
                    "sCode" : "sm",
                    temp : true,
                    type: "Clothing",
                    "sku" : i + "_custom",
                    "name" : item.productName,
                    "category" : "Custom",
                    "fit" : "Unisex",
                    "usage" : "",
                    "sizes" : item.details.size,
                    "weight" : 0.3489583333333333,
                    "countryOrigin": productOrigin,
                    "colours" : [
                        {
                            "name" : item.details.colourName,
                            "price" :item.customProductPrice,
                            "hexVal" : "#ececec",
                            "type" : (item.details.colourName && item.details.colourName.trim().toLowerCase()) == "white" ? "white" : "black",
                            "front" : "https://s3-us-west-2.amazonaws.com/product-image/productPhotos/ATC1000_Form_Front_White.jpg",
                            "back" : "https://s3-us-west-2.amazonaws.com/product-image/productPhotos/ATC1000_Form_Back_White.jpg",
                        }
                    ],
                    "sizePricing" : {
                        "doubleXL" : 2,
                        "tripleXL" : 4
                    },
                    "multiplier" : 1,
                    "__v" : 2
                }
                currentColour = product.colours[0]
            } else if (item.sku === 'contract') {
                product = {
                    "sCode" : "sm",
                    temp : true,
                    type: "Promotional Product",
                    "sku" : i + "_contract",
                    "name" : item.productName,
                    "category" : "Custom",
                    "fit" : "Unisex",
                    "usage" : "",
                    "sizes" : item.details.size,
                    "weight" : 0.3489583333333333,
                    "colours" : [
                        {
                            "name" : item.details.colourName,
                            "price" : 0,
                            "hexVal" : "#ececec",
                            "type" : (item.details.colourName && item.details.colourName.trim().toLowerCase()) == "white" ? "white" : "black",
                        }
                    ],
                    "__v" : 2
                }
                currentColour = product.colours[0]
            } else {
                // Product not found
                if (isBudgeting) {
                    // use dummy product to retrieve print charges
                    product = {
                        "sCode" : "sm",
                        temp : true,
                        type: "Clothing",
                        "sku" : "DISCON_" + item.sku,
                        "name" : item.productName,
                        "category" : "Custom",
                        "fit" : "Unisex",
                        "usage" : "",
                        "sizes" : item.details.size,
                        "weight" : 0.3489583333333333,
                        "countryOrigin": 'CA',
                        "colours" : [
                            {
                                "name" : item.details.colourName,
                                "price" : 0,
                                "hexVal" : "#ececec",
                                "type" : (item.details.colourName && item.details.colourName.trim().toLowerCase()) == "white" ? "white" : "black",
                                "front" : "https://s3-us-west-2.amazonaws.com/product-image/productPhotos/ATC1000_Form_Front_White.jpg",
                                "back" : "https://s3-us-west-2.amazonaws.com/product-image/productPhotos/ATC1000_Form_Back_White.jpg",
                            }
                        ],
                        "sizePricing" : {
                            "doubleXL" : 2,
                            "tripleXL" : 4
                        },
                        "multiplier" : 1,
                        "__v" : 0
                    }
                    currentColour = product.colours[0]
                } else {
                    itemLineErrors.push({
                        lineItemIndex: i,
                        message: 'The product with the specified sku (' + item.sku + ') is discontinued',
                        showModal: true
                    });
                    return;
                }
            }

            chargeBreakdown.product = product;
            var selections = _.map(itemsToLookUp, function(currentItem){
                var productGroup;
                if (item.sku === 'custom' || item.sku === 'contract') {
                    productGroup = product;
                } else {
                    productGroup = _.find(productList, function(individualProduct){
                        return individualProduct.sku == product.sku
                    })
                }

                if (!productGroup && isBudgeting) {
                    productGroup = product;
                }

                if (productGroup){
                    if (!currentColour){
                        currentColour = productGroup.colours[productGroup.colours.length - 1]
                    }
                    return ["", currentColour.type, _.map(currentItem.details.quantity, function(quantity){ return Number(quantity)}),currentItem.details.colourName,currentColour.price ] //[name,colourType,qtyBreakdown,name,price]
                }
            })



            if (item.customization.numberSize > 0 || item.customization.nameSize > 0){
                _.each(item.customization.namesAndNumbers, function(customization){
                    if (!customization.size) {
                        itemLineErrors.push({
                            lineItemIndex: i,
                            message: "Product: " + product.name + ". One or more of your individual name/number customization does not have a size. Please choose a size and try again."
                        })
                    }
                })

                if (placedThroughAdmin) {
                    if (item.customization.numberSize && item.customization.numberSize < 0.35 && item.customization.numberType === 'Embroidery') {
                        itemLineErrors.push({
                            lineItemIndex: i,
                            message: "Product: " + product.name + ". Number Customization - Embroidery size recommended minimum is 0.35 inches"
                        })
                    }
                    if (item.customization.nameSize && item.customization.nameSize < 0.35 && item.customization.nameType === 'Embroidery') {
                        itemLineErrors.push({
                            lineItemIndex: i,
                            message: "Product: " + product.name + ". Name Customization - Embroidery size recommended minimum is 0.35 inches"
                        })
                    }
                }
            }

            var customizationCounts = getNumberOfCustomizations(item.customization.namesAndNumbers);
            var numberOfNames = item.customization.nameSize > 0 ? customizationCounts.namesCount: 0;
            var numberOfNumbers = item.customization.numberSize > 0 ?customizationCounts.numbersCount: 0;


            if (numberOfNames > 0) {
                chargeBreakdown.customizationCharges.push({
                    type: 'Name',
                    quantity: numberOfNames,
                    printType: item.customization.nameType,
                })
            }

            if (numberOfNumbers > 0) {
                chargeBreakdown.customizationCharges.push({
                    type: 'Number',
                    quantity: numberOfNumbers,
                    printType: item.customization.numberType
                })
            }

            var countOfSizes = _.map(item.details.size, function(){
                return 0
            })

            if (item.customization.nameSize > 0 || item.customization.numberSize > 0){
                _.each(item.customization.namesAndNumbers,function(details){
                    countOfSizes[item.details.size.indexOf(details.size)]++
                })
            }

            var notEnough = _.findIndex(countOfSizes, function(sizeCount, index){
                return sizeCount > item.details.quantity[index]
            })

            // contract products dont exist
            if (quantity < product.minimumOrder){
                itemLineErrors.push({
                    lineItemIndex: i,
                    showModal: true,
                    message: "Product: " + product.name + ". Please input more than the minimum order quantity of " + product.minimumOrder
                })
            }

            if (notEnough >= 0 ){
                itemLineErrors.push({
                    lineItemIndex: i,
                    message: "You have added names/numbers that are not in your current sizing breakdown. Please add " + (countOfSizes[notEnough] - item.details.quantity[notEnough] )  + " more \"" + item.details.size[notEnough] + "\" in your item breakdown above before continuing"
                })
            }

            var priceInformation
            var sFromSupp = 8;

            //todo: handle US currencies in other pricing methods
            if (product){
                if (product.type == "Sticker") {
                    priceInformation = calculateStickerPricing(product, item.print,selections)
                } else if (product.category.toLowerCase() === 'sublimation') {
                    priceInformation = calculateSublimationPrice(selections[0],product)
                } else if (product.type == "Promotional Product") {
                    if (item.sku === 'contract') {
                        priceInformation = calculatePromoProductNotOnSitePrice(item.promotionalPricingData, quantity);
                    } else {
                        try {
                            // todo: when us promo supplier support us currencies
                            priceInformation = calculatePromoProductPrice(product, selections[0], item.print);
                        } catch (error) {
                            var promoPricingError;
                            if (placedThroughAdmin) {
                                promoPricingError = error.message;
                            } else {
                                console.error(error);
                                promoPricingError = 'There was an unexpected error calculating the price for product ' + product.name + ' (' + product.sku + '), Colour: ' + selections[0][3] + '. Please contact us at sales@coastalreign.com for assistance!'
                            }
                            itemLineErrors.push({
                                lineItemIndex: i,
                                message: promoPricingError
                            })
                        }
                    }
                } else {
                    var category = _.find(allCats, function(cat){
                        return product.category == cat.name
                    })
                    priceInformation = calculatePrice(product, selections, allPrintsToCalculate(item.print), 0 ,0, totalCsFee , item, suppliers,category)
                }

                if (priceInformation && priceInformation.errors){
                    for (var calcPriceErrorIndex = 0; calcPriceErrorIndex < priceInformation.errors.length; calcPriceErrorIndex++) {
                        var calculatePriceError = priceInformation.errors[calcPriceErrorIndex];
                        calculatePriceError.lineItemIndex = i
                        itemLineErrors.push(calculatePriceError)
                    }
                }

                if (priceInformation && priceInformation.printSuggestions) {
                    _.each(priceInformation.printSuggestions, function(suggestion){
                        var print = _.find(item.print, function(print, index){
                            return suggestion.printLocationIndex === index;
                        })
                        if (print){
                            print.printType = suggestion.printType
                        }
                    })
                }
                if (priceInformation && priceInformation.printCharges) {
                    _.each(priceInformation.printCharges, function (printCharge) {
                        var charge = merge({}, printCharge);
                        var costPerUnit = printCharge.totalCost / totalQtyForItemLine;
                        charge['printCost'] = costPerUnit * quantity;
                        chargeBreakdown.printCharges.push(charge);
                    })
                }
            }

            if (placedThroughAdmin) {
                var invalidPrint = _.find(item.print, function (print, index) {
                    if (!print.name) {
                        return true;
                    }

                    if (print.printColours > 0) {
                        if (!print.width || print.width <= 0) {
                            return true
                        }

                        if (!print.height || print.height <= 0) {
                            return true
                        }
                    }

                    if (print.embroidery && print.embroidery.length > 0) {
                        var invalidEmbroidery = _.find(print.embroidery, function (embroidery) {
                            if (!embroidery.width || embroidery.width <= 0) {
                                return true;
                            }

                            if (!embroidery.height || embroidery.height <= 0) {
                                return true;
                            }

                            if (!print.pmsColours || print.pmsColours.length <= 0) {
                                return true;
                            }
                        })

                        if (invalidEmbroidery) {
                            return true;
                        }
                    } else {
                        if (!print.printColours) {
                            return true
                        }
                    }

                    if ((print.printType === 'Screen Print' || print.printType === "Embroidery") && print.printColours > 0
                        && (!print.pmsColours || print.pmsColours.length <= 0)) {
                        return true;
                    }

                    // vinyl check
                    if (print.printType === 'Vinyl'
                        && print.printColours === 1 &&
                        (!print.vinylColours || print.vinylColours.length <= 0)) {
                        return true;
                    }

                    return false

                })

                if (invalidPrint) {
                    itemLineErrors.push({
                        lineItemIndex: i,
                        message: item.productName + " - Please make sure that the location, width, height and print colours has been entered for all prints"
                    })
                }

            _.forEach(item.print, function (print) {
                // old embroidery prints also have type 'Screen Print' lol. we need to explicitly filter for screen print colours
                if (print.embroidery && print.embroidery.length === 0 && print.printType === 'Screen Print') {
                    if (print.printColours !== print.pmsColours.length) {
                        var hasSpotProcess = _.find(print.pmsColours, function (pmsColour) {
                            return pmsColour.name === 'Spot Process';
                        })
                        if (!hasSpotProcess) {
                            itemLineErrors.push({
                                lineItemIndex: i,
                                message: print.name + ' print requires ' + print.printColours + ' pms colours.'
                            })
                        }
                    }
                }

                if (print.printType === 'Embroidery' && print.pmsColours.length === 0) {
                    itemLineErrors.push({
                        lineItemIndex: i,
                        message: print.name + ' embroidery has no colours added'
                    })
                }
            })
        }

            if ((item.reorderDiscount && item.reorderDiscount != "false") && priceInformation && !item.fixedPrice){
                fixedAmount = fixedAmount + ((priceInformation.totalSetup / totalQtyForItemLine) + csFeePerUnit) * quantity
            }

            var itemIsNotValid = _.find(item.details.quantity, function(quantity){
                return quantity < 0
            })

            if (quantity > 0 && _.isUndefined(itemIsNotValid) && priceInformation){
                var price = (priceInformation.totalCost) / priceInformation.totalQty
                if (product && product.category && product.category.toLowerCase()!= "sublimation"){
                    price = price + ((numberOfNames + numberOfNumbers)/quantity) * customizationCostPerUnit
                }
                if (!itemLines[i].fixedPrice) {
                    var packagingFee = 0
                    if (itemLines[i] && itemLines[i].individualPackaging){
                       //annual increase prone
                        packagingFee = packagingFee + individualPackagingCost
                    }

                    if (itemLines[i] && itemLines[i].individualTagging){
                       //annual increase prone
                        packagingFee = packagingFee + sewnOnTagsCost
                    }
                    chargeBreakdown.includeShippingCharge = true;
                    itemLines[i].price = (price + csFeePerUnit + packagingFee + shippingChargesOver300)
                    itemLines[i].price = Number(itemLines[i].price.toFixed(2))
                }

                // contract pricing has custom formula
                if (itemLines[i].sku === 'contract' && !itemLines[i].fixedPrice) {
                    itemLines[i].price = price
                }
                itemLines[i].totalQty = quantity
            } else {
                if (!priceInformation){
                    // product not found.
                } else {
                    itemLines[i].totalQty = quantity;
                    if (!placedThroughAdmin) {
                        itemLineErrors.push({
                            lineItemIndex: i,
                            message: "Please enter more than 0 items."
                        })
                    }
                }
            }
        })


    return {
        error : itemLineErrors[itemLineErrors.length - 1] ? itemLineErrors[itemLineErrors.length - 1].message : undefined,
        allErrors: itemLineErrors,
        fixedAmount : fixedAmount,
        lineCostMetadata: itemLineChargeBreakdown
    }
}

function getStartingQuantityForProduct(product){
    var startingPointForOrders = 25
    if (product.minimumOrder){
        return Math.max(product.minimumOrder , startingPointForOrders)
    } else {
        return startingPointForOrders
    }
}

function getSelections(selection, additionalSelection){
    var selections = [selection]
    if(additionalSelection.length > 0){
        selections = selections.concat(additionalSelection)
    }
    return selections
}

// modelServiceEnum is injected for function run against DB queries
    //todo needs to be manually synced with dashboardReportService
function getPrintType(print, modelServiceEnum) {
    var models = modelServiceEnum ? modelServiceEnum : mS;
    // handle funky ones first
    var embroideryArray = print.embroidery;
    if (embroideryArray && embroideryArray[0]) {
        var embroidery = embroideryArray[0];
        if (embroidery.subpatch) {
            return models.SPECIALTY_PRINTS.SUBLIMATED_PATCH
        } else if (embroidery.wovenpatch) {
            return models.SPECIALTY_PRINTS.WOVEN_PATCH
        } else if (embroidery.wovenlabel) {
            return models.SPECIALTY_PRINTS.WOVEN_LABEL
        } else if (embroidery.patch) {
            return models.SPECIALTY_PRINTS.EMBROIDERED_PATCH
        } else if (embroidery.applique) {
            return models.SPECIALTY_PRINTS.APPLIQUE
        } else if (embroidery.leather) {
            return models.SPECIALTY_PRINTS.LEATHER_PATCH
        } else if (embroidery.leatherlabel) {
            return models.SPECIALTY_PRINTS.LEATHER_LABEL
        } else if (embroidery.puff) {
            return models.SPECIALTY_PRINTS.PUFF_EMBROIDERY
        } else {
            return models.PRINT_TYPES.EMBROIDERY
        }
    } else {
        if (print.printType === models.PRINT_TYPES.SCREEN_PRINT) {
            if (print.screenPrintOptions) {
                if (print.screenPrintOptions.puff) {
                    return models.SPECIALTY_PRINTS.PUFF_PRINT
                } else if (print.screenPrintOptions.spotProcess) {
                    return models.SPECIALTY_PRINTS.SPOT_PROCESS
                }
            }
        }
        return print.printType;
    }
}

// todo: run migration to set legacy orders
function getOrderCurrency(order) {
    // default
    var currencyObject = {
        currencyCode: mS.ORDER_CURRENCY.CAD,
        exchangeRate: 1
    }
    // once currency 'locked' in refetech only
    if (order.orderCurrency && order.orderCurrency.rateDate) {
        currencyObject = Object.assign({}, order.orderCurrency);
    } else if (order.shippingAddress && order.shippingAddress.country) {
        if (order.shippingAddress.country === 'CA' || order.shippingType === "Pick Up") {

        } else if (order.shippingAddress.country === 'US') {
            currencyObject.currencyCode = mS.ORDER_CURRENCY.USD;
            currencyObject.exchangeRate = fxMap[mS.ORDER_CURRENCY.USD];
            // overrides for legacy orders that have been paid for
            if (order.amountPaid > 0 || (order.currentStatus && (order.currentStatus.status === 'Printing' || order.currentStatus.status === 'Shipped'))) {
                currencyObject.exchangeRate = fxMap['LEGACY_USD']
                currencyObject.rateDate = new Date(null);
            }
        }
    }

    // console.log(currencyObject);
    return currencyObject;
}


// string display value
function getCurrencyValue(value, orderCurrencyObject) {
    return '$' + Math.abs(value * orderCurrencyObject.exchangeRate).toFixed(2) + ' ' + orderCurrencyObject.currencyCode;
}

//transform only
function ca2usOrder(value, orderCurrencyObject) {
    return Number(value) * orderCurrencyObject.exchangeRate;
}

// cannot be 0
function calcUSDCustomsValue(perUnitPrice, quantity) {
    return Math.max(1, ca2us(perUnitPrice * USD_INVOICING_CONVERSION * quantity, 'USD'));
}

function calcUSDCustomsDaysEstimate(usdVal) {
    if (usdVal < US_SHIPMENT_SIZE_VALUE) {
        return 0
    }
    var shipmentsChunks = usdVal / US_SHIPMENT_SIZE_VALUE;
    var buffer = -1;
    if (shipmentsChunks >= 1.5) {
        buffer += Math.floor(Math.ceil(shipmentsChunks) / 2)
    }

    //capped at 3
    buffer = Math.min(buffer, 3);

    return Math.ceil(shipmentsChunks + buffer);
}

// legacy function
// uses current currency map value
function ca2us(value, currency) {
    return Number(value) * fxMap[currency]
}

exports.isAPatchProduct = isAPatchProduct;
exports.isASignageProduct = isASignageProduct;
exports.splitShipmentHandlingCost = splitShipmentHandlingCost
    exports.sizeCodeToName = sizeCodeToName
    exports.getNumberOfCustomizations = getNumberOfCustomizations
    exports.promoProductDecorationGrouping = promoProductDecorationGrouping
    exports.onlyEmbroideryAllowed = onlyEmbroideryAllowed;
    exports.recalculateCart = recalculateCart;
    exports.calculateAveragePrice = calculateAveragePrice;
    exports.getDiscountedPriceFromCode = getDiscountedPriceFromCode
    exports.getSelections = getSelections
    exports.customizationCostPerUnit = customizationCostPerUnit
    exports.seeIfMisMatchSupplierAndProduct = seeIfMisMatchSupplierAndProduct
    exports.getCanadianTaxRate = getCanadianTaxRate
    exports.createDummyItemLine = createDummyItemLine
    exports.createStickerPrint = createStickerPrint
    exports.createPatchPrint = createPatchPrint
    exports.dftPrintC =dftPrintC
    exports.dtgPrintC = dtgPrintC
    exports.calculateScreenPrint = calculateScreenPrint
    exports.calculateEmbroidery = calculateEmbroidery
    exports.calculatePromoProductNotOnSiteCost = calculatePromoProductNotOnSiteCost
    exports.needsStockSwatchColours = needsStockSwatchColours
    exports.getStartingQuantityForProduct = getStartingQuantityForProduct
    exports.getPrintType = getPrintType
    exports.BLANK_LINE_FEE = csrFeeBlank;
    exports.PRINT_LINE_FEE = csrFeePrint;
    exports.SHIPPING_CHARGE = shippingChargesOver300;
    exports.getOrderCurrency = getOrderCurrency;
    exports.getCurrencyValue = getCurrencyValue;
    exports.fxMap = fxMap;
    exports.ca2us = ca2us;
    exports.ca2usOrder= ca2usOrder;
    exports.individualPackagingCost = individualPackagingCost;
    exports.sewnOnTagsCost = sewnOnTagsCost;
    exports.calcUSDCustomsValue = calcUSDCustomsValue;
    exports.calcUSDCustomsDaysEstimate = calcUSDCustomsDaysEstimate;
    exports.US_SHIPMENT_SIZE_VALUE = US_SHIPMENT_SIZE_VALUE;
    exports.NON_GUARANTEED_EXTRA_DAYS = NON_GUARANTEED_EXTRA_DAYS;
    exports.shippingPercentageWithinRange = shippingPercentageWithinRange;

})(typeof exports === 'undefined'?
    this['calculationService']={}: exports);
