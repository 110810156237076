(function () {
    'use strict';

    angular.module('sharedModule')
        .directive('breadcrumb', BreadcrumbDirective);

    BreadcrumbDirective.$inject = ['PageTemplateService', '$rootScope', '$anchorScroll', '$timeout']

    function BreadcrumbDirective(PageTemplateService, $rootScope, $anchorScroll, $timeout) {
        var directive = {
            link: link,
            templateUrl: 'shared/js/widget/breadcrumb.html',
            restrict: 'EA',
            scope: {
                breadcrumblist: '='
            }
        };
        return directive;

        function link($scope, element, attrs) {

            $scope.$watch('breadcrumblist', function() {
                // console.log($scope.breadcrumblist);
                $timeout(function() {
                    var scrollable = document.getElementById("breadcrumblist");
                    if (scrollable) {
                        scrollable.scrollLeft = scrollable.scrollWidth;
                    }
                })
            })

            $scope.cleanPageName = function(name) {
                return PageTemplateService.cleanPageName(name);
            }
        }
    }
})();