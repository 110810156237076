(function (exports) {

    var shirtBack = {
        name: "Back", //make this an enum of "Front", "Left", "Right", "Back"
        defaultDesignerImage: "../images/designerDefault/defaultBackShirt.svg"
    }

    var defaultFront = {
        name: "Front",
        defaultDesignerImage: ""
    }


    var shirtLeft = {
        name: "Left",
        defaultDesignerImage: "../images/designerDefault/leftSleeve.svg"
    }

    var shortSleeveLeft = {
        name: "Left",
        defaultDesignerImage: "../images/designerDefault/shortSleeveLeft.svg"
    }

    var shortSleeveRight = {
        name: "Right",
        defaultDesignerImage: "../images/designerDefault/shortSleeveRight.svg"
    }

    var shortSleeveBack = {
        name: "Back",
        defaultDesignerImage: "../images/designerDefault/shortSleeveBack.svg"
    }

    var shirtRight = {
        name: "Right",
        defaultDesignerImage: "../images/designerDefault/rightSleeve.svg"
    }

    var hatBack = {
        name: "Back",
        defaultDesignerImage: "../images/designerDefault/hatBack.svg"
    }

    var bagLeft = {
        name: "Left",
        defaultDesignerImage: "../images/designerDefault/blankSquareBack.svg"
    }

    var bagRight = {
        name: "Right",
        defaultDesignerImage: "../images/designerDefault/blankSquareBack.svg"
    }

    var drinkWareBack = {
        name: "Back",
        defaultDesignerImage: "../images/designerDefault/drinkWareBack.svg"
    }


    var hatLeft = {
        name: "Left",
        defaultDesignerImage: "../images/designerDefault/hatLeft.svg"
    }

    var golfBallBack = {
        name: "Back",
        defaultDesignerImage: "../images/designerDefault/golfBallBack.svg"
    }

    var hatRight = {
        name: "Right",
        defaultDesignerImage: "../images/designerDefault/hatRight.svg"
    }

    var toqueBack = {
        name: "Back",
        defaultDesignerImage: "../images/designerDefault/toqueBack.svg"
    }

    var bucketHatBack = {
        name: "Back",
        defaultDesignerImage: "../images/designerDefault/bucketHatBack.svg"
    }


    var blankSquareBack = {
        name: "Back",
        defaultDesignerImage: "../images/designerDefault/blankSquareBack.svg"
    }

    var toteBagBack = {
        name: "Back",
        defaultDesignerImage: "../images/designerDefault/toteBagBack.svg"
    }

    var notebookBack = {
        name: "Back",
        defaultDesignerImage: "../images/designerDefault/notebookBack.svg"
    }

    var mugBack = {
        name: "Back",
        defaultDesignerImage: "../images/designerDefault/mugBack.svg"
    }


    var pantsBack = {
        name: "Back",
        defaultDesignerImage: "../images/designerDefault/pantsBack.svg"
    }

    var defaultCategory = {
        name: "Default",
        designerMultiplier: 1,
        screenPrintAllowed: true,
        printAllowed: {screenPrint: true, transfer: true},
        defaultWeight: 0.8,
        printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight]
    }

    var allCategories = [
        defaultCategory,
        {
            name: "Short Sleeve T Shirts",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shortSleeveBack, shortSleeveLeft, shortSleeveRight],
            categoryUrl: '/custom-products/custom-clothing/t-shirts/short-sleeve',
            hsCode: '6109.10.0004',
        },
        {
            name: "Long Sleeve T Shirts",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/t-shirts/long-sleeve',
            hsCode: '6109.10.0004',
        },
        {
            name: "Tank Tops",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack],
            categoryUrl: '/custom-products/custom-clothing/t-shirts/tank-tops',
            hsCode: '6109.10.0004',
        },
        {
            name: "Hoodies",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/hoodies-sweaters/hoodies',
            hsCode: '6110.20.20.10',
        },
        {
            name: "Fleece Quarter Zip Sweatshirts",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/jackets/fleece',

            hsCode: '6110.20.20.10',

        },
        {
            name: "Quarter Zip Sweatshirts",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/hoodies-sweaters/quarter-zip-sweatshirts',

            hsCode: '6110.20.20.10',

        },
        {
            name: "Full Zip Sweatshirts",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/hoodies-sweaters/full-zip-sweatshirts',

            hsCode: '6110.20.20.10',

        },
        {
            name: "Winter Jackets",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/jackets/winter',
            hsCode: '6210.30.50.10'
        },
        {
            name: "Wind Breakers",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/jackets/lightweight',
            hsCode: '6210.30.50.10'
        },
        {
            name: "3 in 1 System Jackets",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/jackets/3-in-1-system',
            hsCode: '6210.30.50.10'

        },
        {
            name: "5 in 1 Jackets",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/jackets/5-in-1-system',
            hsCode: '6210.30.50.10'
        },
        {
            name: "Anorak Jackets",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/jackets/anorak',
            hsCode: '6210.30.50.10'
        },
        {
            name: "Raincoats",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/jackets/raincoat',
            hsCode: '6210.30.50.10'
        },
        {
            name: "Accessories",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, blankSquareBack],
            categoryUrl: '',
            hsCode: '6214.30.0000'
        },
        {
            name: "Kilt",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '',
            hsCode: '6204.53.30.10'
        },
        {
            name: "Sweatpants",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, pantsBack],
            categoryUrl: '/custom-products/custom-clothing/pants-bottoms/sweatpants',

            hsCode: '6110.20.20.10',

        },
        {
            name: "Soft Shell Jackets",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/jackets/soft-shell',
            hsCode: '6210.30.50.10'
        },
        {
            name: "Polos",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shortSleeveBack, shortSleeveLeft, shortSleeveRight],
            categoryUrl: '/custom-products/custom-clothing/polos/short-sleeves',
            hsCode: '6105.10.0010'
        },
        {
            name: "Vests",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack],
            categoryUrl: '/custom-products/custom-clothing/jackets/vests',
            hsCode: '6210.30.50.10'
        },
        {
            name: "Tote Bags",
            designerMultiplier: 2,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, toteBagBack],
            categoryUrl: '/custom-products/custom-bags/tote-bags',
            hsCode: '4202.92.1500'
        },
        {
            name: "Backpacks",
            designerMultiplier: 2,
            printAllowed: {screenPrint: false, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront,],
            categoryUrl: '/custom-products/custom-bags/custom-backpacks',
            hsCode: '4202.92.9100',

        },
        {
            name: "Flat Brim Hats",
            designerMultiplier: 4,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, hatBack, hatLeft, hatRight],
            categoryUrl: '/custom-products/custom-clothing/hats/flat-brimmed',
            hsCode: '6505.00.15.40',
        },
        {
            name: "Curved Brim Hats",
            designerMultiplier: 4,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, hatBack, hatLeft, hatRight],
            categoryUrl: '/custom-products/custom-clothing/hats/dad-hats',
            hsCode: '6505.00.15.40'
        },
        {
            name: "Toques",
            designerMultiplier: 5,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, toqueBack],
            categoryUrl: '/custom-products/custom-clothing/hats/toques',
            hsCode: '6505.00.15.40',
        },
        {
            name: "Fleece Jackets",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/jackets/fleece',

            hsCode: '6110.20.20.10',

        },
        {
            name: "Fleece Vests",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack],
            categoryUrl: '/custom-products/custom-clothing/jackets/vests',
            hsCode: '6210.30.50.10'
        },
        {
            name: "Puffer Jackets",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/jackets/puffer',
            hsCode: '6210.30.50.10'
        },
        {
            name: "Zip Hoodies",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/hoodies-sweaters/zip-hoodies',

            hsCode: '6110.20.20.10',

        },
        {
            name: "Shorts",
            designerMultiplier: 2,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, pantsBack],
            categoryUrl: '/custom-products/custom-clothing/pants-bottoms/shorts',
            hsCode: '6103.42.10.50'
        },
        {
            name: "Long Sleeve Button Down Shirts",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/button-downs',
            hsCode: '6105.10.0010'
        },
        {
            name: "Short Sleeve Button Down Shirts",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shortSleeveBack, shortSleeveLeft, shortSleeveRight],
            categoryUrl: '/custom-products/custom-clothing/button-downs',
            hsCode: '6105.10.0010'
        },
        {
            name: "Crewneck Sweatshirts",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/hoodies-sweaters/crewneck-sweatshirts',

            hsCode: '6110.20.20.10',

        },
        {
            name: "Uniforms",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/work-wear',
            hsCode: '6103.22.00.10'
        },
        {
            name: "Long Sleeve Polos",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/polos/long-sleeves',
            hsCode: '6105.10.0010'
        },
        {
            name: "Sublimation",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront,],
            categoryUrl: '/custom-products/jerseys/sublimated-apparel/all',
            hsCode: '6114.30.3060'
        },
        {
            name: "Luggage",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront,],
            categoryUrl: '',
            hsCode: '4202.92.9100',

        },
        {
            name: "Golf Bags",
            designerMultiplier: 2,
            printAllowed: {screenPrint: false, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront,],
            categoryUrl: '',
            hsCode: '4202.92.9100',

        },
        {
            name: "Fanny Packs",
            designerMultiplier: 4,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront,],
            categoryUrl: '/custom-products/custom-bags/custom-fanny-packs',
            hsCode: '4202.92.9100',

        },
        {
            name: "Casual Jackets",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/jackets/casual',
            hsCode: '6210.30.50.10'

        },
        // {
        //     name: "Leather Jackets",
        //     designerMultiplier: 1,
        //     printAllowed: {screenPrint: false, transfer: false},
        //     defaultWeight: 0.8,
        //     printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
        //     categoryUrl: '/custom-products/custom-clothing/jackets/all'
        // },
        {
            name: "Laptop Bags",
            designerMultiplier: 4,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, blankSquareBack],
            categoryUrl: '/custom-products/custom-bags/custom-laptop-bags',
            hsCode: '4202.92.9100',

        },
        {
            name: "Casual Sweaters",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/hoodies-sweaters/casual-sweaters',
            hsCode: '6110.20.20.10',

        },
        {
            name: "Pants",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, pantsBack],
            categoryUrl: '/custom-products/custom-clothing/pants-bottoms/pants',
            hsCode: '6103.42.10.50'
        },
        {
            name: "Safety Pants",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, pantsBack],
            categoryUrl: '/custom-products/custom-clothing/work-wear/safety/pants',
            hsCode: '6110.20.20.10',

        },
        {
            name: "Puffer Vests",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack],
            categoryUrl: '/custom-products/custom-clothing/jackets/vests',
            hsCode: '6210.30.50.10'
        },
        {
            name: "Roller Bag",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront,],
            categoryUrl: '/custom-products/custom-bags/custom-roller-bags',
            hsCode: '4202.92.9100',
        },
        {
            name: "Safety Uniforms",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/work-wear/safety/overalls-coveralls',
            hsCode: '6103.22.00.10'
        },
        {
            name: "Safety Short Sleeve T Shirts",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shortSleeveBack, shortSleeveLeft, shortSleeveRight],
            categoryUrl: '/custom-products/custom-clothing/work-wear/safety/t-shirts',
            hsCode: '6109.10.0004',
        },
        {
            name: "Safety Long Sleeve T Shirts",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/work-wear/safety/long-sleeve',
            hsCode: '6109.10.0004',
        },
        {
            name: "Safety Hoodies",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/work-wear/safety/hoodies',
            hsCode: '6110.20.20.10'
        },
        {
            name: "Zippered Sweatshirts",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/hoodies-sweaters',
            hsCode: '6110.20.20.10',

        },
        {
            name: "Safety Zippered Sweatshirts",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/work-wear/safety/zip-hoodies',
            hsCode: '6110.20.20.10',
        },
        {
            name: "Safety Sweatpants",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, pantsBack],
            categoryUrl: '/custom-products/custom-clothing/work-wear/safety/pants',
            hsCode: '6110.20.20.10',
        },
        {
            name: "Lightweight Jackets",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/jackets/lightweight',
            hsCode: '6210.30.50.10'
        },
        {
            name: "Safety Lightweight Jackets",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/work-wear/safety/jackets',
            hsCode: '6210.30.50.10'
        },
        {
            name: "Safety Soft Shell Jackets",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/work-wear/safety/jackets',
            hsCode: '6210.30.50.10'
        },
        {
            name: "Safety Vests",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack],
            categoryUrl: '/custom-products/custom-clothing/work-wear/safety/vests',
            hsCode: '6210.30.50.10'
        },
        {
            name: "Safety Flat Brim Hats",
            designerMultiplier: 4,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, hatBack, hatLeft, hatRight],
            categoryUrl: '/custom-products/custom-clothing/work-wear/safety',
            hsCode: '6505.00.15.40',
        },
        {
            name: "Safety Curved Brim Hats",
            designerMultiplier: 4,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, hatBack, hatLeft, hatRight],
            categoryUrl: '/custom-products/custom-clothing/work-wear/safety',
            hsCode: '6505.00.15.40',
        },
        {
            name: "Safety Toques",
            designerMultiplier: 4,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, toqueBack],
            categoryUrl: '/custom-products/custom-clothing/hats/toques/embroidered',
            hsCode: '6505.00.15.40',
        },
        {
            name: "Safety Fleece Jackets",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/work-wear/safety/jackets',
            hsCode: '6110.20.20.10',
        },
        {
            name: "Safety Fleece Vests",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack],
            categoryUrl: '/custom-products/custom-clothing/work-wear/safety/vests',
            hsCode: '6110.20.20.10',
        },
        {
            name: "Safety Puffer Jackets",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/work-wear/safety/jackets',
            hsCode: '6210.30.50.10'
        },
        {
            name: "Insulated Jackets",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/jackets/insulated',
            hsCode: '6210.30.50.10'
        },
        {
            name: "Safety Insulated Jackets",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/work-wear/safety/jackets',
            hsCode: '6210.30.50.10'
        },
        {
            name: "Safety Zip Hoodies",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/work-wear/safety/zip-hoodies',
            hsCode: '6110.20.20.10',

        },
        {
            name: "Safety Shorts",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, pantsBack],
            categoryUrl: '/custom-products/custom-clothing/work-wear/safety/pants',

            hsCode: '6110.20.20.10',

        },
        {
            name: "Safety Long Sleeve Button Down Shirts",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/work-wear/safety/long-sleeve',
            hsCode: '6105.10.0010'
        },
        {
            name: "Safety Short Sleeve Button Down Shirts",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/work-wear/safety/t-shirts',
            hsCode: '6105.10.0010'
        },
        {
            name: "Safety Crewneck Sweatshirts",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/work-wear/safety/hoodies',
            hsCode: '6110.20.20.10',

        },
        {
            name: "Track Jackets",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/jackets/track',

            hsCode: '6110.20.20.10',

        },
        {
            name: "Bucket Hats",
            designerMultiplier: 4,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, bucketHatBack],
            categoryUrl: '/custom-products/custom-clothing/hats/bucket-hats',
            hsCode: '6505.00.15.40',
        },
        {
            name: "Leggings",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, pantsBack],
            categoryUrl: '/custom-products/custom-clothing/pants-bottoms',
            hsCode: '6115.22.0000'
        },
        {
            name: "Dress",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack],
            // categoryUrl: '/custom-products/custom-clothing/dresses',
            hsCode: '6204.42.3060'
        },
        {
            name: "Bras",
            designerMultiplier: 2,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront,],
            categoryUrl: '/custom-products/custom-clothing/ladies',
            hsCode: '6212.10.9020'
        },
        {
            name: "Cinch Bags",
            designerMultiplier: 2,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront,],
            categoryUrl: '/custom-products/custom-bags/custom-cinch-bags',
            hsCode: '4202.92.9100',
        },
        {
            name: "Duffle Bags",
            designerMultiplier: 2,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, bagRight, bagLeft, blankSquareBack],
            categoryUrl: '/custom-products/custom-bags/duffel-bags',
            hsCode: '4202.92.9100',

        },
        {
            name: "Collared Jackets",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/jackets/casual',
            hsCode: '6210.30.50.10'
        },
        {
            name: "Varsity Jackets",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/jackets/varsity',
            hsCode: '6210.30.50.10'
        },
        {
            name: "Scarves",
            designerMultiplier: 4,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack],
            categoryUrl: '/custom-products/custom-clothing/scarves/embroidered-scarves',
            hsCode: '6214.30.0000'
        },
        {
            name: "Lunch Coolers",
            designerMultiplier: 4,
            printAllowed: {screenPrint: false, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, bagRight, bagLeft, blankSquareBack],
            categoryUrl: '/custom-products/custom-bags/coolers',
            hsCode: '4202.92.9100',

        },
        {
            name: "Laptop and Tablet Cases",
            designerMultiplier: 4,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, blankSquareBack],
            categoryUrl: '/custom-products/custom-bags/custom-laptop-tablet-sleeves',
            hsCode: '4202.92.9100',
        },
        {
            name: "Drink and Bottle Bags",
            designerMultiplier: 4,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, blankSquareBack],
            categoryUrl: '/custom-products/custom-bags/custom-drink-and-bottle-bags',
            hsCode: '4202.92.1500'
        },
        {
            name: "Aprons",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront,],
            categoryUrl: '/custom-products/custom-clothing/work-wear/restaurant/aprons',
            hsCode: '6103.22.00.10'
        },
        {
            name: "Chef Coats",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/work-wear/restaurant/chef-coats',
            hsCode: '6103.22.00.10'
        },
        {
            name: "Bomber Jacket",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/jackets/bomber-jacket',
            hsCode: '6210.30.50.10'
        },
        {
            name: "Overall",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack],
            categoryUrl: '/custom-products/custom-clothing/pants-bottoms/overalls',
            hsCode: '6103.42.20.25'
        },
        {
            name: "Visor",
            designerMultiplier: 4,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, hatBack, hatLeft, hatRight],
            categoryUrl: '/custom-products/custom-clothing/hats/visors',
            hsCode: '6505.00.15.40',
        },
        {
            name: "Blankets",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, blankSquareBack],
            categoryUrl: '/custom-products/promotional/outdoor-leisure/blankets',
            hsCode: '6301.40.0010'
        },
        {
            name: "Socks",
            designerMultiplier: 4,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront],
            categoryUrl: '/custom-products/custom-clothing/socks/basic-print',
            hsCode: '6115.30.9010'
        },
        {
            name: "Chef Wear",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront],
            categoryUrl: '/custom-products/custom-clothing/work-wear/restaurant',
            hsCode: '6103.22.00.10'
        },
        {
            name: "Dog Wear",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront],
            hsCode: '4201.00.6000'
        },
        // {
        //     name: "Blouse",
        //     designerMultiplier: 1,
        //     printAllowed: {screenPrint: true, transfer: true},
        //     defaultWeight: 0.8,
        //     printLocations: [defaultFront]
        // },
        // {
        //     name: "Pyjamas",
        //     designerMultiplier: 1,
        //     printAllowed: {screenPrint: true, transfer: true},
        //     defaultWeight: 0.8,
        //     printLocations: [defaultFront]
        // },
        {
            name: "Golf Towels",
            designerMultiplier: 2,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, blankSquareBack],
            categoryUrl: '/custom-products/jerseys/sports/golf/towels',
            hsCode: '6302.60.0020'
        },
        {
            name: "Sports Towels",
            designerMultiplier: 2,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, blankSquareBack],
            categoryUrl: '/custom-products/jerseys/sports/towels',
            hsCode: '6302.60.0020'
        },
        {
            name: "Beach Towels",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, blankSquareBack],
            categoryUrl: '/custom-products/promotional/outdoor-leisure/towels/beach-towels',
            hsCode: '6302.60.0020'
        },
        {
            name: "Hand Towels",
            designerMultiplier: 2,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, blankSquareBack],
            categoryUrl: '/custom-products/promotional/outdoor-leisure/towels/hand-towels',
            hsCode: '6302.60.0020'
        },
        {
            name: "Bath Towels",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, blankSquareBack],
            categoryUrl: '/custom-products/promotional/outdoor-leisure/towels/bath-towels',
            hsCode: '6302.60.0020'
        },
        {
            name: "Cardigans",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/hoodies-sweaters/casual-sweaters',

            hsCode: '6110.20.20.10',

        },
        // {
        //     name: "Knit Sweaters",
        //     designerMultiplier: 1,
        //     printAllowed: {screenPrint: true, transfer: true},
        //     defaultWeight: 0.8,
        //     printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
        //     categoryUrl: '/custom-products/custom-clothing/hoodies-sweaters'
        // },
        // {
        //     name: "Jumpers",
        //     designerMultiplier: 1,
        //     printAllowed: {screenPrint: true, transfer: true},
        //     defaultWeight: 0.8,
        //     printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight]
        // },
        // {
        //     name: "Skirt",
        //     designerMultiplier: 1,
        //     printAllowed: {screenPrint: true, transfer: true},
        //     defaultWeight: 0.8,
        //     printLocations: [defaultFront, pantsBack],
        //     categoryUrl: '/custom-products/custom-clothing/ladies'
        // },
        // {
        //     name: "Skort",
        //     designerMultiplier: 1,
        //     printAllowed: {screenPrint: true, transfer: true},
        //     defaultWeight: 0.8,
        //     printLocations: [defaultFront, pantsBack],
        //     categoryUrl: '/custom-products/custom-clothing/ladies'
        // },
        {
            name: "Short Pants",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, pantsBack],
            categoryUrl: '/custom-products/custom-clothing/pants-bottoms/shorts',
            hsCode: '6103.42.10.50'
        },
        {
            name: "Athletic Pants",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, pantsBack],
            categoryUrl: '/custom-products/jerseys/pants',
            hsCode: '6114.30.3060'
        },
        // {
        //     name: "Blazers",
        //     designerMultiplier: 1,
        //     printAllowed: {screenPrint: true, transfer: true},
        //     defaultWeight: 0.8,
        //     printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
        //     categoryUrl: '/custom-products/custom-clothing/jackets'
        // },
        {
            name: "Bibs",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront],
            categoryUrl: '/custom-products/custom-clothing/youth/all',
            hsCode: '6111.20.6020'
        },
        {
            name: "Onesies",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/youth/onesies',
            hsCode: '6111.20.6020'
        },
        {
            name: "Masks",
            designerMultiplier: 4,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront],
            categoryUrl: '/custom-products/promotional/face-masks',
            hsCode: '9505.90.60.00'
        },
        {
            name: "Gloves",
            designerMultiplier: 4,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront],
            hsCode: '6116.93.94.00'
        },
        {
            name: "Journals",
            designerMultiplier: 4,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.25,
            printLocations: [defaultFront, notebookBack],
            categoryUrl: '/custom-products/promotional/office-supplies/notebooks',
            hsCode: '4820.10.20.20'
        },
        {
            name: "Bottles",
            designerMultiplier: 4,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, drinkWareBack],
            categoryUrl: '/custom-products/promotional/drinkware/custom-bottles',
            hsCode: '7615.10.91.00'
        },
        {
            name: "Tumblers",
            designerMultiplier: 4,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, mugBack],
            categoryUrl: '/custom-products/promotional/drinkware/tumblers',
            hsCode: '7615.10.91.00'
        },
        {
            name: "Mugs",
            designerMultiplier: 4,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, mugBack],
            categoryUrl: '/custom-products/promotional/drinkware/mugs',
            hsCode: '6912.00.4400'
        },
        {
            name: "Insulated Mugs",
            designerMultiplier: 4,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, mugBack],
            categoryUrl: '/custom-products/promotional/drinkware/mugs',
            hsCode: '7615.10.91.00'
        },
        {
            name: "Bottle Openers",
            designerMultiplier: 4,
            defaultWeight: 0.1,
            printAllowed: {screenPrint: true, transfer: true},
            printLocations: [defaultFront, blankSquareBack],
            categoryUrl: '/custom-products/promotional/outdoor-leisure/bottle-opener',
            hsCode: '7616.99.51.60'
        },
        {
            name: "Pens",
            designerMultiplier: 3,
            defaultWeight: 0.025,
            printAllowed: {screenPrint: true, transfer: true},
            printLocations: [defaultFront, blankSquareBack],
            categoryUrl: '/custom-products/promotional/office-supplies/custom-pens',
            hsCode: '9608.10.0000'
        },
        {
            name: "Stickers",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront],
            categoryUrl: '/custom-products',
            hsCode: '4911.91.2040'
        },
        {
            name: "Patches",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.1,
            printLocations: [defaultFront],
            categoryUrl: '/custom-products/patches',
            hsCode: '5810.91.0010'
        },
        {
            name: "Padfolios",
            designerMultiplier: 3,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, blankSquareBack],
            categoryUrl: '/custom-products/promotional/office-supplies/padfolio',
            hsCode: '4820.10.20.20'
        },
        {
            name: "USB",
            designerMultiplier: 4,
            defaultWeight: 0.025,
            printAllowed: {screenPrint: true, transfer: true},
            printLocations: [defaultFront],
            categoryUrl: '/custom-products/promotional/tech/usb',
            hsCode: '8523.51.0000'
        },
        {
            name: "Chargers",
            designerMultiplier: 4,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront],
            categoryUrl: '/custom-products/promotional/tech/portable-chargers',
            hsCode: '8504.40.8500'
        },
        {
            name: "Power Banks",
            designerMultiplier: 4,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront],
            categoryUrl: '/custom-products/promotional/tech/portable-chargers',
            hsCode: '8504.40.8500'
        },
        {
            name: "Headphones",
            designerMultiplier: 3,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront],
            categoryUrl: '/custom-products/promotional/tech/headphones',
            hsCode: '8518.30.2000'
        },
        {
            name: 'Toys',
            designerMultiplier: 3,
            defaultWeight: 1,
            printAllowed: {screenPrint: true, transfer: true},
            printLocations: [defaultFront, blankSquareBack],
            hsCode: '9503.00.00.90'
        },
        {
            name: "Lanyards",
            designerMultiplier: 3,
            defaultWeight: 0.065,
            printAllowed: {screenPrint: true, transfer: true},
            printLocations: [defaultFront],
            categoryUrl: '/custom-products/promotional/office-supplies/lanyards',
            hsCode: '6307.90.40.20'
        },
        {
            name: 'Mousepads',
            designerMultiplier: 1,
            defaultWeight: 0.5,
            printAllowed: {screenPrint: true, transfer: true},
            printLocations: [defaultFront, blankSquareBack],
            hsCode: '8473.30.91.00'
        },
        {
            name: 'Pillows',
            designerMultiplier: 1,
            defaultWeight: 0.5,
            printAllowed: {screenPrint: true, transfer: true},
            printLocations: [defaultFront, defaultFront],
            hsCode: '9404.90.10.30'
        },
        {
            name: "Stress Relievers",
            designerMultiplier: 4,
            defaultWeight: 0.065,
            printAllowed: {screenPrint: true, transfer: true},
            printLocations: [defaultFront, blankSquareBack],
            categoryUrl: '/custom-products/promotional/office-supplies/stress-balls',
            hsCode: '9503.00.00.90'
        },
        {
            name: "Umbrellas",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, blankSquareBack],
            categoryUrl: '/custom-products/promotional/outdoor-leisure/umbrellas',
            hsCode: '6601.10.0000'
        },
        {
            name: "Sunglasses",
            designerMultiplier: 3,
            defaultWeight: 0.05,
            printAllowed: {screenPrint: true, transfer: true},
            printLocations: [defaultFront],
            categoryUrl: '/custom-products/promotional/outdoor-leisure/sunglasses',
            hsCode: '9004.10.00.00'
        },
        {
            name: "Coasters",
            designerMultiplier: 3,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront],
            categoryUrl: '/custom-products/promotional/outdoor-leisure/coasters',
            hsCode: '4419.90.91.00'
        },
        {
            name: "Sticky Notes",
            designerMultiplier: 3,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront],
            categoryUrl: '/custom-products/promotional/office-supplies/sticky-notes',
            hsCode: '4820.10.20.20'
        },
        {
            name: "Keychains",
            designerMultiplier: 4,
            defaultWeight: 0.05,
            printAllowed: {screenPrint: true, transfer: true},
            printLocations: [defaultFront],
            hsCode: '7616.99.51.60'
        },
        {
            name: "Koozies",
            designerMultiplier: 4,
            defaultWeight: 0.02,
            printAllowed: {screenPrint: true, transfer: true},
            printLocations: [defaultFront, blankSquareBack],
            categoryUrl: '/custom-products/promotional/drinkware/beer-koozies',
            hsCode: '3924.10.4000'
        },
        {
            name: "Candles",
            designerMultiplier: 4,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.5,
            printLocations: [defaultFront, blankSquareBack],
            categoryUrl: '/custom-products/promotional/outdoor-leisure/candles',
            hsCode: '3406.00.0000'

        },
        {
            name: "Speakers",
            designerMultiplier: 4,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront],
            categoryUrl: "/custom-products/promotional/tech/speakers",
            hsCode: '8517.62.0090'
        },
        {
            name: "Non-woven Shopping Bags",
            designerMultiplier: 2,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, toteBagBack],
            categoryUrl: '/custom-products/custom-bags/shopping/non-woven',
            hsCode: '4202.92.9100',

        },
        {
            name: "Grocery Bags",
            designerMultiplier: 2,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, toteBagBack],
            categoryUrl: '/custom-products/custom-bags/shopping/grocery',
            hsCode: '4202.92.1500'
        },
        {
            name: "Recycled Shopping Bags",
            designerMultiplier: 2,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, toteBagBack],
            categoryUrl: "/custom-products/custom-bags/shopping/recycled",
            hsCode: '4202.92.9100',
        },
        {
            name: "Laminated Shopping Bags",
            designerMultiplier: 2,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, toteBagBack],
            categoryUrl: '/custom-products/custom-bags/shopping/laminated',
            hsCode: '4202.92.9100',
        },
        {
            name: "Insulated Shopping Bags",
            designerMultiplier: 2,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, toteBagBack],
            categoryUrl: "/custom-products/custom-bags/shopping/insulated",
            hsCode: '4202.92.9100',
        },
        {
            name: "Golf Balls",
            designerMultiplier: 4,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 1,
            printLocations: [defaultFront, golfBallBack],
            categoryUrl: '/custom-products/jerseys/sports/golf/balls',
            hsCode: '9506.32.0000'
        },
        {
            name: "Hockey Jerseys",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/jerseys/sports/hockey',
            hsCode: '6114.30.3060'
        },
        {
            name: "Football Jerseys",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/jerseys/sports',
            hsCode: '6114.30.3060'
        },
        {
            name: "Volleyball Jerseys",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/jerseys/sports/volleyball',
            hsCode: '6114.30.3060'
        },
        {
            name: "Basketball Jerseys",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/jerseys/sports/basketball',
            hsCode: '6114.30.3060'
        },
        {
            name: "Baseball Jerseys",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/jerseys/sports/baseball',
            hsCode: '6114.30.3060'
        },
        {
            name: "Soccer Jerseys",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/jerseys/sports/soccer',
            hsCode: '6114.30.3060'
        },
        // {
        //     name: "Lunch Boxes",
        //     designerMultiplier: 4,
        //     printAllowed: {screenPrint: true, transfer: true},
        //     defaultWeight: 0.8,
        //     printLocations: [defaultFront, bagRight, bagLeft]
        // },
        {
            name: "Scrubs",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/work-wear/scrubs',
            hsCode: '6103.22.00.10'
        },
        {
            name: "Swag Boxes",
            designerMultiplier: 4,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.5,
            printLocations: [defaultFront],
            hsCode: '4819.10.0040'
        },
        {
            name: "Greeting Cards",
            designerMultiplier: 4,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.06,
            printLocations: [defaultFront],
            hsCode: '4909.00.4000'
        },
    ]

//DESIGNER STUFF AND TOOLS

// standard print locations
    var leftChest = 'Left Chest'
    var rightChest = 'Right Chest'
    var front = 'Front'
    var back = 'Back'
    var upperLeftSleeve = 'Left Sleeve'
    var upperRightSleeve = 'Right Sleeve'
    var leftCuff = 'Left Cuff'
    var rightCuff = 'Right Cuff'
    var backNape = 'Back Nape'
    var leftSide = 'Left Side'
    var rightSide = 'Right Side'
    var leftThigh = 'Left Thigh'
    var rightThigh = 'Right Thigh';

    var standardPrintLocations = [
        leftChest, rightChest, front, back, upperLeftSleeve, upperRightSleeve, leftCuff, rightCuff, backNape, leftSide, rightSide, leftThigh, rightThigh
    ]
    var standardEmbroideryFonts = [
        'Arial', 'Athletic', 'Constania', 'Microsoft Sans Serif', 'Market', 'Handy Script', 'Block 2', 'Old English Font', 'Oswald', 'Tinos', 'Script 2', 'Sitka Font'
    ]

    exports.standardPrintLocations = standardPrintLocations;
    exports.standardEmbroideryFonts = standardEmbroideryFonts;
    exports.allCategories = allCategories;
    exports.defaultCategory = defaultCategory;

})(typeof exports === 'undefined' ?
    this['categoryService'] = {} : exports);
