/**
 * Created by eddisonng on 2016-10-13.
 */

function showError(message) {
    var message = message;
    if (message == null) {
        message = "Unfortunately there was an error with your request."
    }
    $("#errorMessage").html(message)
    $("#errorMessageModal").modal()
}

function note(message, callback) {
    var message = message;
    $("#noteMessage").html(message);
    $("#noteMessageModal").modal()
    $('#noteMessageModal').unbind('hidden.bs.modal')
    $('#noteMessageModal').on('hidden.bs.modal', callback)
}

function success(message, callback) {
    $('#successMessageModal').unbind('hidden.bs.modal')
    var message = message;
    if (message == null) {
        message = "Success!"
    }
    $("#successMessage").html(message)
    $("#successMessageModal").modal()
    if (callback) {
        $('#successMessageModal').on('hidden.bs.modal', callback)
    }
}


function loadingScreen(message) {
    // close any pre-existing screens
    closeLoadingScreen();
    var loadingScreenObj = window.pleaseWait({
        logo: "../images/loading-image.png",
        backgroundColor: 'white',
        loadingHtml: "<p class='loading-message'>" + message + "</p>" + '<div id="progressBarLoading" class="progress" style="width: 5%; height: 100%; background-color: #39bdae; border-radius: 0;"></div>'
    });
    window.loading_screen = loadingScreenObj;
    return loadingScreenObj;
}

function closeLoadingScreen() {
    if (window.loading_screen) {
        window.loading_screen.finish();
    }
}
