(function() {
    'use strict';

    angular.module('app')
        .factory('OrderService', OrderService)

    OrderService.$inject = ['$http', '$q'];

    function OrderService($http, $q) {

        function approveQuote(order, userDetails) {
            return $http.post('/approveQuote', {
                order: order,
                userDetails: userDetails
            })
                .then(function(response) {
                    return response.data
                })
                .catch(function(err) {
                    showError(err.data.message);
                    return $q.reject(err);
                })
        }

        function saveQuoteDetails(orderNumber, order) {
            return $http.patch('/api/orders/saveQuoteDetails/' + orderNumber, {
                order: order
            })
                .then(function(response) {
                    return response.data
                })
                .catch(function(err) {
                    // fail silently
                    return $q.reject(err);
                })
        }

        function updateQuoteItemLine(order) {
            return $http.patch('/api/orders/updateQuoteItemLine/' + order.number, {
                order: order
            })
                .then(function(response) {
                    return response.data
                })
                .catch(function(err) {
                    showError(err.data.message);
                    return $q.reject(err);
                })
        }

        function trackOrder(orderNumber, zip) {
            return $http.post('/api/orders/trackOrder/' + orderNumber + '/' + zip)
                .then(function(response) {
                    return response.data
                })
                .catch(function(err) {
                    showError(err.data.message);
                    return $q.reject(err);
                })
        }

        function rejectMockup(orderNumber, viewToken, rejectionNotes) {
            return $http.post('/api/orders/declineMockup/' + orderNumber, {
                viewToken: viewToken,
                rejectionNotes: rejectionNotes
            })
                .then(function(response) {
                    return response.data
                })
                .catch(function(err) {
                    showError(err.data.message);
                    return $q.reject(err);
                })
        }

        function approveTerms(orderNumber, viewToken, socialMediaConsent, signature) {
            return $http.post('/api/orders/approveTerms/' + orderNumber, {
                viewToken: viewToken,
                socialMediaConsent: socialMediaConsent,
                signature :signature
            })
                .then(function(response) {
                    return response.data
                })
                .catch(function(err) {
                    return $q.reject(err);
                })
        }

        function payBalance(orderNumber, viewToken, cardDetails) {
            return $http.post('/api/orders/payBalance/' + orderNumber, {
                viewToken: viewToken,
                cardDetails: cardDetails
            })
                .then(function(response) {
                    return response.data
                })
                .catch(function(err) {
                    return $q.reject(err);
                })
        }

        function acceptMockupAndPay(orderNumber, viewToken, cardDetails) {
            return $http.post('/api/orders/approveMockup/' + orderNumber, {
                viewToken: viewToken,
                cardDetails: cardDetails
            })
                .then(function(response) {
                    return response.data
                })
                .catch(function(err) {
                    return $q.reject(err);
                })
        }

        function requestNewInvoice(orderNumber, viewToken) {
            return $http.post('/api/orders/requestStockCheck/' + orderNumber, {
                viewToken: viewToken
            })
                .then(function(response) {
                    return response.data
                })
                .catch(function(err) {
                    showError(err.data.message);
                    return $q.reject(err);
                })
        }

        function getOrderRates(toAddress, shipments, warehouse) {
            var req = {
                address: toAddress,
                shipments: shipments,
                warehouse: warehouse
            }
            return $http.post('/api/orders/getOrderShippingRates', req)
                .then(function(response) {
                    return response.data
                })
                .catch(function(err) {
                    showError(err.data.message);
                    return $q.reject(err);
                })
        }

        function recommendedWarehouse(shippingAddress, itemLines, pickUpLocation, failSilent) {
            var req = {
                shippingAddress: shippingAddress,
                itemLines: itemLines,
                pickUpLocation: pickUpLocation
            }
            return $http.post('/api/orders/recommendedWarehouse', req)
                .then(function(response) {
                    return response.data
                })
                .catch(function(err) {
                    if (!failSilent) {
                        showError(err.data.message);
                    }
                    return $q.reject(err);
                })
        }

        function estimateShippingRates(zip, parcels, warehouse) {
            var req = {
                zip: zip,
                parcels: parcels,
                warehouse: warehouse
            }
            return $http.post('/api/orders/estimateShippingRates', req)
                .then(function(response) {
                    return response.data
                })
                .catch(function(err) {
                    return $q.reject(err);
                })
        }

        return {
            trackOrder: trackOrder,
            rejectMockup: rejectMockup,
            acceptMockupAndPay: acceptMockupAndPay,
            payBalance: payBalance,
            approveTerms : approveTerms,
            requestNewInvoice: requestNewInvoice,
            estimateShippingRates: estimateShippingRates,
            getOrderRates: getOrderRates,
            recommendedWarehouse: recommendedWarehouse,
            approveQuote: approveQuote,
            saveQuoteDetails: saveQuoteDetails,
            updateQuoteItemLine: updateQuoteItemLine
        };
    }

})();
